.footer {
	background-color: #626262;
	color: #fff;
	padding-top: 102px;
	padding-bottom: 54px;
	@media(max-width: $sw-md-max) {
		padding: 80px 0 45px;
	}
	@media(max-width: $sw-sm-max) {
		padding: 60px 0 40px;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0 30px;
	}
	@media(max-width: $sw-xx-max) {
		padding: 25px 0;
	}

	&__wrap {
		padding-top: 57px;
		width: 985px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
		@media(max-width: $sw-md-max) {
			padding-top: 50px;
		}
		@media(max-width: $sw-sm-max) {
			padding-top: 40px;
		}
		@media(max-width: $sw-xs-max) {
			padding-top: 30px;
		}
		@media(max-width: $sw-xx-max) {
			padding-top: 25px;
		}
	}

	&__office {
		display: inline-block;
		margin-left: 36px;
		@media(max-width: $sw-md-max) {
			margin-left: 30px;
		}
		@media(max-width: $sw-sm-max) {
			margin-left: 20px;
		}
		@media(max-width: $sw-xx-max) {
			margin-left: 0;
			text-align: center;
			margin-top: 20px;
		}

		&-item {
			display: inline-block;
			width: 220px;
			letter-spacing: 1.1px;
			vertical-align: top;
			@media(max-width: $sw-sm-max) {
				display: block;
				width: 260px;
			}
			@media(max-width: $sw-xx-max) {
				width: auto;
			}
			&:nth-child(n + 2) {
				margin-left: 18px;
				@media(max-width: $sw-sm-max) {
					margin-left: 0;
					margin-top: 20px;
				}
			}
			&-descr {
				font-weight: 300;
				margin-top: 20px;
				line-height: 1.76;
				@media(max-width: $sw-sm-max) {
					margin-top: 10px;
				}
			}
		}
	}

	&__copyrights {
		font-size: 12px;
		letter-spacing: 0.2px;
		font-weight: 300;
		text-align: center;
		margin-top: 52px;
		@media(max-width: $sw-md-max) {
			margin-top: 42px;
		}
		@media(max-width: $sw-sm-max) {
			margin-top: 30px
		}
		@media(max-width: $sw-xs-max) {
			margin-top: 25px;
		}
	}


	&__nav {
		width: 462px;
		display: inline-block;
		vertical-align: top;
		@media(max-width: $sw-md-max) {
			width: 422px;
		}
		@media(max-width: $sw-sm-max) {
			width: auto;
		}
		@media(max-width: $sw-xx-max) {
			text-align: center;
		}
	}

	&__main {
		@media(max-width: $sw-sm-max) {
			display: flex;
			justify-content: space-between;
		}
		@media(max-width: $sw-xx-max) {
			flex-direction: column;
			align-items: center;
		}
	}

	&__list {
		@media(max-width: $sw-sm-max) {
			display: flex;
			flex-direction: column;
		}
		li {
			display: inline-block;
			vertical-align: top;
			width: 220px;
			@media(max-width: $sw-md-max) {
				width: 200px;
			}
			@media(max-width: $sw-sm-max) {
				width: 100%;
			}
			&.active {
				a {
					border-color: #bcc2ff;
					color: #bcc2ff;
					text-shadow: 1px 0 #bcc2ff;
				}
			}
			
			&:nth-child(2n) {
				margin-left: 18px;
				@media(max-width: $sw-sm-max) {
					margin-left: 0;
				}
			}
			&:nth-child(n + 3) {
				margin-top: 21px;
			}
			&:nth-child(n + 2) {
				@media(max-width: $sw-sm-max) {
					margin-top: 15px;
				}
				@media(max-width: $sw-xx-max) {
					margin-top: 10px;
				}
			}
			a {
				display: inline-block;
				color: #fff;
				font-weight: 300;
				padding-left: 16px;
				letter-spacing: 1.2px;
				border-left: 3px solid transparent;
				transition: border-color 0.15s ease, color 0.15s ease, text-shadow 0.15s ease;
				@media(max-width: $sw-xx-max) {
					padding-left: 0;
					padding-bottom: 2px;
					border-left: none;
					border-bottom: 2px solid transparent;
				}
				
				&:hover {
					border-color: #bcc2ff;
					color: #bcc2ff;
					text-shadow: 1px 0 #bcc2ff;
				}
			}
		}
	}

	&_main {
	    background-color: #fff;

	    &__copyrights {
	    	text-align: center;
			font-size: 12px;
			color: rgb(172, 172, 172);
			font-weight: 300;
			padding-bottom: 45px;
			letter-spacing: 0.2px;

			@media(max-width: $sw-xx-max) {
				padding: 5px 0 30px;
			}
	    }
	}
}
