.section-service-page {
	padding: 98px 0 90px;

	@media(max-width: $sw-md-max) {
		padding: 70px 0;
	}
	@media(max-width: $sw-sm-max) {
		padding: 55px 0;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}

	p {
		line-height: 1.45;
		letter-spacing: 1.04px;
	}
}

.service-page {
	&__wrap {
		display: flex;

		@media(max-width: $sw-sm-max) {
			flex-direction: column-reverse;
		}
	}
	&__nav {
		width: 260px;
		flex-shrink: 0;
		background-color: #f1f1f1;
		padding: 29px 28px;
		margin-left: 81px;
	
		@media(max-width: $sw-md-max) {
			width: 255px;
			margin-left: 35px;
		}
		@media(max-width: $sw-sm-max) {
			margin-left: 0;
			width: 100%;
			padding: 20px;
			opacity: 0;
			visibility: hidden;
			position: absolute;
			left: 0;
			top: 29px;
			max-height: 300px;
			overflow-y: auto;
			transition: all 0.15s ease;
		}

		&-decoration {
			&-img-1 {
				bottom: -447px;
				right: 16px;
				@media(max-width: $sw-md-max) {
					bottom: -300px;
				}
				@media(max-width: $sw-sm-max) {
					display: none;
				}
			}
		}
		

		&-wrap {
			position: relative;
			align-self: flex-start;

			@media(max-width: $sw-sm-max) {
				align-self: auto;
			}

			&.open {
				.service-page__nav {
					@media(max-width: $sw-sm-max) {
						opacity: 1;
						visibility: visible;
						position: absolute;
					}
				}
				.service-page__nav-btn-line {
					&:before {
						@media(max-width: $sw-sm-max) {
							transform: rotateZ(90deg);
						}
					}
				}
			}
		}

		&-ttl {
			text-align: center;
			@media(max-width: $sw-sm-max) {
				display: none;
			}
		}

		&-btn {
			position: relative;
			padding-left: 40px;

			@media(min-width: $sw-md-min) {
				display: none;
			}

			&-line {
				width: 34px;
				height: 34px;
				background-color: #f1f1f1;
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				margin: auto;

				&:after,
				&:before {
					content: '';
					position: absolute;
					background-color: #144b93;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					margin: auto;
					transition: transform 0.15s ease;
				}

				&:after {
					width: 24px;
					height: 4px;
				}

				&:before {
					height: 24px;
					width: 4px;
				}
			}
		}
	}

	&__main {
		@media(max-width: $sw-sm-max) {
			margin-top: 30px;
		}
		&-img {
			text-align: center;

			&:nth-child(n + 2) {
				margin: 53px 0 51px;
				@media(max-width: $sw-sm-max) {
					margin: 30px 0;
				}
			}
		}

		&-images {
			margin: 32px 0 47px;
			display: flex;
			@media(max-width: $sw-sm-max) {
				margin: 25px 0;
			}
			@media(max-width: $sw-xx-max) {
				flex-wrap: wrap;
			}
			&-item {
				margin-right: 15px;
				@media(max-width: $sw-xx-max) {
					margin-bottom: 15px;
					margin-right: 0;
				}
				&:last-child {
					margin-right: 0;
					@media(max-width: $sw-xx-max) {
						margin-bottom: 0;
					}
				}
			}
		}

		p {
			&:nth-child(n + 2) {
				margin-top: 20px;
				@media(max-width: $sw-sm-max) {
					margin-top: 15px;
				}
			}
		}
	}

	&__list {
		margin-top: 27px;
		@media(max-width: $sw-sm-max) {
			display: flex;
			flex-wrap: wrap;
			margin-top: 0;
		}

		li {
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 1.1px;
			line-height: 1.28;
			
			@media(max-width: $sw-sm-max) {
				width: 30%;
				margin-right: 5%;
			}
			@media(max-width: $sw-xs-max) {
				width: 100%;
				margin-right: 0;
			}

			&.active {
				a {
					color: rgb(20, 75, 147);
				}
			}

			&:nth-child(n + 2) {
				margin-top: 16px;

				@media(max-width: $sw-sm-max) {
					margin-top: 0;
				}

				@media(max-width: $sw-xs-max) {
					margin-top: 12px;
				}
			}

			&:nth-child(3n) {
				@media(max-width: $sw-sm-max) {
					margin-right: 0;
				}
			}

			&:nth-child(n + 4) {
				@media(max-width: $sw-sm-max) {
					margin-top: 12px;
				}
			}

			a {
				&:hover {
					color: rgb(20, 75, 147);
					transition: color 0.15s ese;
				}
			}
		}
	}

	&__form {
		&-wrap {
			margin-top: 55px;
			max-width: 640px;
			@media(max-width: $sw-sm-max) {
				margin-top: 30px;
			}
		}
		&-ttl {
			text-align: center;
		}
		&-inner {
			margin-top: 41px;
			display: flex;
			@media(max-width: $sw-sm-max) {
				margin-top: 25px;
			}
			@media(max-width: $sw-xs-max) {
				flex-wrap: wrap;
			}
		}
		&-inputs {
			width: 320px;
			margin-right: 20px;
			.form-group {
				&:nth-child(n + 2) {
					margin-top: 13px;
				}
			}
			@media(max-width: $sw-xs-max) {
				width: 100%;
				margin-right: 0;
			}
		}
		&-textarea {
			width: 300px;
			@media(max-width: $sw-xs-max) {
				width: 100%;
				margin-top: 13px;
			}
		}
		&-btn {
			margin-top: 21px;
			text-align: right;
		}
	}
}