.section-mission {
	padding: 49px 0 192px;
	@media(max-width: $sw-md-max) {
		padding: 40px 0 120px;
	}
	@media(max-width: $sw-sm-max) {
		padding: 30px 0 110px;
	}
	@media(max-width: $sw-xs-max) {
		padding: 25px 0 30px;
	}
}

.mission {
	&__wrap {
		position: relative;
	}

	&__title {
		text-align: center;
		max-width: 600px;
		margin: 0 auto;
		@media(max-width: $sw-sm-max) {
			max-width: 400px;
		}

		h2 {
			color: rgb(20, 75, 147);
		}
	}

	&__descr {
		display: flex;
		justify-content: space-between;
		margin-top: 47px;
		@media(max-width: $sw-md-max) {
			margin-top: 37px;
		}
		@media(max-width: $sw-xs-max) {
			flex-direction: column;
			margin-top: 20px;
		}

		p {
			&:nth-child(n + 2) {
				margin-top: 16px;
				@media(max-width: $sw-xs-max) {
					margin-top: 12px;
				}
			}
		}

		&-lt,
		&-rt {
			width: 48.7%;
			@media(max-width: $sw-xs-max) {
				width: 100%;
			}
		}

		&-rt {
			@media(max-width: $sw-xs-max) {
				margin-top: 16px;
			}
			@media(max-width: $sw-xs-max) {
				margin-top: 12px;
			}
		}
	}

	&__message {
		position: relative;
		font-size: 34px;
		color: rgb(20, 75, 147);
		line-height: 1.412;
		text-align: center;
		letter-spacing: 2.4px;
		margin: 27px auto 0;
		max-width: 750px;
		@media(max-width: $sw-md-max) {
			font-size: 30px;
		}
		@media(max-width: $sw-sm-max) {
			font-size: 26px;
			margin-top: 20px;
		}
		@media(max-width: $sw-xs-max) {
			font-size: 22px;
		}
		@media(max-width: $sw-xx-max) {
			font-size: 18px;
			margin-top: 16px;
		}

		&-img {
			position: absolute;
			top: -38px;
			left: -54px;
			z-index: 3;
			pointer-events: none;
			@media(max-width: $sw-md-max) {
				transform: scale(0.6);
				top: -36px;
				left: -4px;
			}
			@media(max-width: $sw-sm-max) {
				display: none;
			}
		}
	}

	&__decoration {
		&-img {
			position: absolute;
			z-index: 3;
			pointer-events: none;
			@media(max-width: $sw-md-max) {
				transform: scale(0.6);
			}

			&.js-parallax-foreground {
				img {
					@media(max-width: $sw-md-max) {
						transform: scale(0.6);
					}
				}
			}
			&-1 {
				top: -111px;
				left: 15px;
				@media(max-width: $sw-md-max) {
					top: -91px;
					left: 10px;
				}
				@media(max-width: $sw-xs-max) {
					top: -80px;
					left: 0;
				}
				@media(max-width: $sw-xx-max) {
					display: none;
				}
			}
			&-2 {
				right: 120px;
				top: -30px;
				@media(max-width: $sw-md-max) {
					right: 30px;
					top: -38px;
				}
				@media(max-width: $sw-sm-max) {
					right: 0;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			&-3 {
				bottom: -191px;
				left: -196px;
				@media(max-width: $sw-md-max) {
					bottom: -152px;
					left: -176px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			&-4 {
				right: -174px;
				bottom: -148px;
				@media(max-width: $sw-md-max) {
					right: -124px;
					bottom: -118px
				}
				@media(max-width: $sw-sm-max) {
					right: -123px;
					bottom: -147px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
		}
	}
}