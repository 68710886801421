.section-socials {
	position: fixed;
	z-index: 10;
	right: 0;
	top: 50vh;
	transform: translateY(-50%);
	box-shadow: 0px 13px 13.8px 1.2px rgba(0, 0, 0, 0.1);
}

.socials {
	&__item {
		width: 42px;
		height: 42px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
		color: #c1bfbe;
		font-size: 16px;
		@media(max-width: $sw-sm-max) {
			width: 34px;
			height: 34px;
			font-size: 13px;
		}

		&:hover {
			color: #858584;
			transition: color 0.15s ease;
		}

		&:nth-child(n + 2) {
			border-top: 1px solid #e5e5e4;
		}

		.icon {
			&-facebook,
			&-instagram {
				font-size: 1.125em;
			}
			&-youtube,
			&-twitter {
				font-size: 0.875em;
			}
		}
	}
}