@mixin font-face ($fontname, $fontfile, $fontweight: normal, $fontstyle: normal) {
	@font-face {
		font-family: $fontname;
		src: url('../fonts/' + $fontfile + '.eot');
		src: url('../fonts/' + $fontfile + '.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/' + $fontfile + '.woff') format('woff'),
			 url('../fonts/' + $fontfile + '.ttf') format('truetype'),
			 url('../fonts/' + $fontfile + '.svg##{fontname}') format('svg');
		font-weight: $fontweight;
		font-style: $fontstyle;
	}
};

@include font-face('Montserrat', 'Montserrat/MONTSERRAT-LIGHT', 300, normal);
@include font-face('Montserrat', 'Montserrat/MONTSERRAT-REGULAR', 400, normal);
@include font-face('Montserrat', 'Montserrat/MONTSERRAT-MEDIUM', 500, normal);
@include font-face('Montserrat', 'Montserrat/MONTSERRAT-SEMIBOLD', 600, normal);
@include font-face('Montserrat', 'Montserrat/MONTSERRAT-BOLD', 700, normal);

@include font-face('Sansita', 'Sanista/SANSITA-BOLDITALIC', 700, italic);