.section-services {
	padding-top: 99px;
	@media(max-width: $sw-md-max) {
		padding-top: 70px;
	}
	@media(max-width: $sw-sm-max) {
		padding-top: 50px;
	}
	@media(max-width: $sw-xs-max) {
		padding-top: 40px;
	}
}

.services {
	&__title {
		text-align: center;

		h2 {
			position: relative;
			display: inline-block;
		}

		&-decoration {
			&-img-1 {
				left: -186px;
				top: -124px;
				@media(max-width: $sw-md-max) {
					left: -166px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
		}
	}
	&__items {
		position: relative;
		margin-top: 76px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@media(max-width: $sw-md-max) {
			margin-top: 60px;
		}
		@media(max-width: $sw-sm-max) {
			margin-top: 40px;
		}
		@media(max-width: $sw-xs-max) {
			margin-top: 25px;
		}

		&-decoration {
			.mission__decoration-img {
				img {
					@media(max-width: 1380px) {
						transform: scale(0.65);
					}
				}
			}
			&-img {
				&-1 {
					top: -14px;
					right: 195px;
					@media(max-width: $sw-md-max) {
						top: -58px;
						right: 165px;
					}
					@media(max-width: $sw-xs-max) {
						display: none;
					}
				}
				&-2 {
					bottom: 265px;
					left: 241px;
					@media(max-width: $sw-md-max) {
						bottom: 239px;;
						left: 191px;
					}
					@media(max-width: $sw-xs-max) {
						display: none;
					}
				}
			}
		}
	}
	&__item {
		position: relative;
		height: 350px;
		width: 16.53%;
		display: flex;
		justify-content: center;
		padding: 105px 15px 5px;
		margin: 0 0.164% 2px 0;
		overflow: hidden;
		@media(max-width: 1500px) {
			height: 320px;
			padding-top: 90px;
		}
		@media(max-width: 1380px) {;
			height: 270px;
			padding-top: 60px;
		}
		@media(max-width: $sw-md-max) {
			width: 33.2%; 
		}
		@media(max-width: $sw-sm-max) {
			padding: 80px 10px 5px;
		}
		@media(max-width: $sw-xs-max) {
			width: 49.8%;
		}
		@media(max-width: $sw-xx-max) {
			width: 100%;
			margin-right: 0;
			height: 220px;
			padding-top: 50px;
		}

		&:nth-child(6n) {
			margin-right: 0;
		}
		&:nth-child(3n) {
			@media(max-width: $sw-md-max) {
				margin-right: 0;
			}
			@media(max-width: $sw-xs-max) {
				margin-right: 0.164%;
			}
		}

		&:nth-child(2n) {
			@media(max-width: $sw-xs-max) {
				margin-right: 0;
			}
		}

		&:hover {
			&:after {
				opacity: 0.51;
			}
			&:before {
				opacity: 0;
			}
			.services__item-img {
				filter: #{"grayscale(0)"};
				-webkit-filter: #{"grayscale(0)"};
				-moz-filter: #{"grayscale(0)"};
				-ms-filter: #{"grayscale(0)"};
				-o-filter: #{"grayscale(0)"};

				img {
					filter: blur(0);
				}
			}
			.services__item-info {
				color: #fff;
				transform: translateY(93px);
				@media(max-width: 1500px) {
					transform: translateY(60px);
				}
				@media(max-width: $sw-xx-max) {
					transform: translateY(30px);
				}
			}
		}

		&-img {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			height: 100%;
			width: 100%;
			filter: #{"grayscale(30%)"};
			-webkit-filter: #{"grayscale(30%)"};
			-moz-filter: #{"grayscale(30%)"};
			-ms-filter: #{"grayscale(30%)"};
			-o-filter: #{"grayscale(30%)"};
			transition: filter 0.15s ease;

			img {
				filter: blur(4.4px);
			}
		}

		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: #fff;
			opacity: 0.7;
			z-index: 1;
			transition: opacity 0.15s ease;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			background-image: linear-gradient(to top, #4056f8 0%, transparent 80%);
			background-image: -moz-linear-gradient(to top, #4056f8 0%, transparent 80%);
			background-image: -webkit-linear-gradient(to top, #4056f8 0%, transparent 80%);
			background-image: -ms-linear-gradient(to top, #4056f8 0%, transparent 80%);
			opacity: 0;
			transition: opacity 0.15s ease;
		}

		&-info {
			position: relative;
			color: rgb(20, 75, 147);
			line-height: 1.125;
			text-align: center;
			max-width: 190px;
			z-index: 2;
			transform: translateY(0);
			transition: color 0.15s ease, transform 0.15s ease;
		}

		&-icon {
			display: flex;
			justify-content: center;
			font-size: 62px;
			height: 75px;
			overflow: hidden;
			@media(max-width: $sw-sm-max) {
				font-size: 54px;
				height: 62px;
			}
		}

		&-descr {
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 1px;
			margin-top: 12px;
			@media(max-width: $sw-sm-max) {
				font-size: 15px;
				margin-top: 8px;
			}
		}
	}
}