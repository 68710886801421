.section-banner {
	position: relative;
	background: transparent;
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	
	&_main {
		padding: 273px 0 426px;
		position: relative;
		background-attachment: fixed;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: #000;
			opacity: 0.08;
		}

		@media(max-width: 1380px) {
			padding: 205px 0 224px;
		}
		@media(max-width: $sw-md-max) {
			background-size: 140%;
		}
		@media(max-width: $sw-sm-max) {
			padding: 150px 0 180px;
		}
		@media(max-width: $sw-xs-max) {
			padding: 100px 0 150px;
		}
		@media(max-width: $sw-xx-max) {
			padding: 60px 0 100px;
			background-size: 160%;
		}
		.banner__title {
			span {
				&:first-child {
					letter-spacing: 5.3px;
					@media(max-width: $sw-xs-max) {
						letter-spacing: 3px;
					}
				}
				&:nth-child(2) {
					margin-top: 12px;
					@media(max-width: $sw-xs-max) {
						margin-top: 4px;
					}
				}
				&:last-child {
					line-height: 0.73;
				}
			}
		}
	}

	&_pages {
		background-position: center;
		padding: 114px 0 184px;
		@media(max-width: $sw-md-max) {
			padding: 100px 0 150px;
		}
		@media(max-width: $sw-sm-max) {
			padding: 85px 0 130px;
		}
		@media(max-width: $sw-xs-max) {
			padding: 70px 0 120px;
		}
		@media(max-width: $sw-xx-max) {
			padding: 60px 0 100px;
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: #858584;
			opacity: 0.25;
		}

		&-service {
			@media(min-width: $sw-lg-min) {
				padding: 178px 0 193px;
			}
		}

		&-pricing {
			@media(min-width: $sw-lg-min) {
				padding: 169px 0 201px;
			}
		}

		&-insurance {
			background-position-y: 29%;
			@media(min-width: $sw-lg-min) {
				padding: 168px 0 202px;
			}
		}

		&-contacts {
			@media(min-width: $sw-lg-min) {
				padding: 178px 0 192px;
			}
		}

		&-testimonials {
			background-position-y: 35%;
		}
	}
}

.banner {
	&__title {
		position: relative;
		margin: 0 auto;
		color: #fff;
		font-weight: bold;
		display: flex;
		flex-direction: column;
		text-align: center;
		z-index: 1;

		.font-sansita {
			letter-spacing: 7.3px;
			line-height: 1;
			@media(max-width: $sw-xs-max) {
				letter-spacing: 4px;
			}
		}
	}
	
	&__scroll {
		position: absolute;
		bottom: 112px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		flex-direction: column;
		z-index: 1;
		cursor: pointer;
		@media(max-width: 1380px) {
			bottom: 80px;
		}
		@media(max-width: $sw-md-max) {
			bottom: 60px;
		}
		@media(max-width: $sw-xs-max) {
			display: none;
		}

		&-name {
			font-size: 16px;
			color: #fff;
			letter-spacing: 1px;
			@media(max-width: 1380px) {
				font-size: 14px;
			}
		}

		&-line {
			display: block;
			background-color: #fff;
			width: 1px;
			height: 80px;
			margin-top: 14px;
			@media(max-width: 1380px) {
				height: 50px;
				margin-top: 10px;
			}
			@media(max-width: $sw-sm-max) {
				display: none;
			}
		}

		&-mouse {
			position: relative;
			border: 1px solid #fff;
			width: 17px;
			height: 30px;
			border-radius: 8px;
			margin-top: 20px;
			@media(max-width: 1380px) {
				margin-top: 15px;
			}

			&:before {
				content: '';
				position: absolute;
				top: 4px;
				left: 0;
				right: 0;
				margin: auto;
				height: 6px;
				width: 3px;
				border-radius: 2px;
				background-color: #fff;
			}
		}
	}
}

.section-after {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2;
		border-color: transparent transparent #fff transparent;
		border-style: solid;
		border-width: 0 0 62px 100vw;

		@media(max-width: $sw-md-max) {
			border-width: 0 0 50px 100vw;
		}
		@media(max-width: $sw-sm-max) {
			border-width: 0 0 40px 100vw;
		}
		@media(max-width: $sw-xs-max) {
			border-width: 0 0 35px 100vw;
		}
		@media(max-width: $sw-xx-max) {
			border-width: 0 0 25px 100vw;
		}
	}

	&_main {
		&:after {
			border-width: 0 0 122px 100vw;
			@media(max-width: $sw-md-max) {
				border-width: 0 0 90px 100vw;
			}
			@media(max-width: $sw-sm-max) {
				border-width: 0 0 70px 100vw;
			}
			@media(max-width: $sw-xs-max) {
				border-width: 0 0 55px 100vw;
			}
			@media(max-width: $sw-xx-max) {
				border-width: 0 0 30px 100vw;
			}
		}
	}
}

.section-before {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 2;
		border-color: #fff transparent transparent transparent;
		border-style: solid;
		border-width: 102px 100vw 0 0;

		@media(max-width: $sw-md-max) {
			border-width: 80px 100vw 0 0;
		}
		@media(max-width: $sw-sm-max) {
			border-width: 60px 100vw 0 0;
		}
		@media(max-width: $sw-xs-max) {
			border-width: 40px 100vw 0 0;
		}
		@media(max-width: $sw-xx-max) {
			border-width: 25px 100vw 0 0;
		}
	}
}