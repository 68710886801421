.l-wrapper{
	position: relative;
	min-height: 100vh;
	overflow: hidden;

	&_padding {
		padding-top: 111px;
		@media(max-width: 1500px) {
			padding-top: 110px;
		}
		@media(max-width: 1380px) {
			padding-top: 94px;
		}
		@media(max-width: $sw-sm-max) {
			padding-top: 72px;
		}
		@media(max-width: $sw-xx-max) {
			padding-top: 70px;
		}
	}
}