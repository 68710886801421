.section-office {
	display: flex;
	margin-top: 5px;
	@media(max-width: $sw-sm-max) {
		flex-direction: column;
	}
}

.office {
	&__lt {
		width: 54.3%;
		@media(max-width: $sw-sm-max) {
			height: 300px;
			width: 100%;
		}
		@media(max-width: $sw-xx-max) {;
			height: 250px;
		}
	}

	&__rt {
		position: relative;
		width: 45.7%;
		@media(max-width: $sw-sm-max) {;
			width: 100%;
		}
	}

	&__link {
		text-align: center;
		position: absolute;
		bottom: 136px;
		left: 0;
		right: 0;
		z-index: 3;
		@media(max-width: $sw-sm-max) {;
			bottom: auto;
			top: 275px;
		}
		@media(max-width: $sw-xx-max) {;
			top: 225px;
		}
	}

	&__map {
		height: 700px;

		@media(max-width: $sw-md-max) {
			height: 550px;
		}

		@media(max-width: $sw-sm-max) {
			height: 400px;
		}

		@media(max-width: $sw-xx-max) {;
			height: 250px;
		}

		&-gradient {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-image: -moz-linear-gradient(to right, #fff 0%, transparent 100%);
				background-image: -webkit-linear-gradient(to right, #fff 0%, transparent 100%);
				background-image: -ms-linear-gradient(to right, #fff 0%, transparent 100%);
				background-image: linear-gradient(to right, #fff 0%, transparent 100%);
				opacity: 0.3;
				z-index: 1;
				pointer-events: none;
			}
		}

		&-descr {
			position: absolute;
			top: 100px;
			left: 11.8vw;
			z-index: 1;
			pointer-events: none;

			@media(max-width: $sw-md-max) {;
				top: 60px;
			}
			@media(max-width: $sw-xx-max) {;
				top: 30px;
			}
		}

		&-ttl {
			letter-spacing: 4.3px;
		}

		&-address {
			font-size: 16px;
			line-height: 1.5;
			font-weight: 500;
			margin-top: 66px;
			letter-spacing: 1.3px;

			@media(max-width: $sw-md-max) {;
				margin-top: 50px;
			}
			@media(max-width: $sw-sm-max) {;
				font-size: 15px;
				margin-top: 40px;
			}
			@media(max-width: $sw-xs-max) {;
				font-size: 14px;
			}
			@media(max-width: $sw-xx-max) {;
				font-size: 13px;
				margin-top: 20px;
			}
		}
	}
}

.map {
	height: 100%;
	width: 100%;
}