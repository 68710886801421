.section-slider {
	overflow: hidden;
}

$angle: 4.5deg;

.slider {
	&__item {
		@media(max-width: $sw-xs-max) {
			position: relative;
			height: 300px;

			&-img {
				width: 100%;
				height: 100%;
			}
			&-cont {
				position: absolute;
				z-index: 1;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				text-align: center;
				padding: 35px 0;

				&-ttl {
					position: relative;
					span {
						display: block;
					}
				}

				&-link {
					margin-top: 20px;
				}
			}
		}

		@media(max-width: $sw-xx-max) {
			height: 250px;
		}

		@media(min-width: $sw-sm-min) {
			transform: skewY(-$angle);
			position: relative;
			overflow: hidden;
			width: 100%;

			&:before {
				visibility: hidden;
				display: block;
				content: '';
			}
			&-top {
				margin-top: -5.5%;

				&:before {
					padding-bottom: 38.3%;
				}
				.slider__item-img {
					bottom: -62%;
					top: 5%;
				}
				.slider__item-cont {
					top: 9vw;
				}
				.slider__item-cont-link {
					margin-top: 12.3vw;

					@media(max-width: 1380px) {
						margin-top: 8vw;
					}
				}

				&-img {
					
				}
			}
			&-bot {
				margin-bottom: -5%;

				&:before {
					padding-bottom: 30.2%;
				}
				.slider__item-img {
					bottom: -44%;
					top: -15%;
				}

				.slider__item-cont {
					top: 6.8vw;
					max-height: 182px;
					overflow: hidden;
					@media(max-width: $sw-md-max) {
						max-height: 150px;
					}
					@media(max-width: $sw-sm-max) {
						max-height: 110px;
					}
				}

				.slider__item-cont-ttl {
					span {
						&:first-child {
							letter-spacing: 2.7px;
							line-height: 1;
						}
						&:last-child {
							line-height: 1;
						}
					}
				}
			}
			&-img {
				transform: skewY($angle);
				position: absolute;
				right: 0;
				left: 0;
			}
			&-cont {
				transform: skewY($angle);
				position: absolute;
				display: block;
				width: 100%;
				text-align: center;
				color: #fff;

				&-ttl {
					.font-sansita {
						letter-spacing: 10px;
					}

					span {
						display: block;

						&.img-wrap {
							display: inline-block;
							position: relative;
						}
					}
				}
			}
		}
	}
	&__decoration {
		&-img-1 {
			right: -41px;
			top: -25px;
			@media(max-width: $sw-xs-max) {
				display: none;
			}
		}
	}
}

.slick-dots {
	position: absolute;
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	bottom: 20px;
	@media(min-width: $sw-sm-min) {
		bottom: 2.7vw;
	}
	li {
		display: inline-block;
		width: 12px;
		height: 12px;
		border: 2px solid #fff;
		background-color: transparent;
		border-radius: 50%;
		cursor: pointer;
		transition: color 0.15s ease, border-color 0.15s ease;

		@media(max-width: $sw-sm-max) {
			width: 10px;
			height: 10px;
		}

		&:nth-child(n + 2) {
			margin-left: 13px;
			@media(max-width: $sw-sm-max) {
				margin-left: 10px;
			}
		}

		&.slick-active {
			background-color: #144b93;
			border-color: #144b93;
		}

		button {
			display: none;
		}
	}
}

.slider-img_parallax {
	position: relative;
	background: transparent;
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}