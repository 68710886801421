.section-pagination {}

.pagination {
	&__list {
		li {
			display: inline-block;
			margin-right: 9px;

			&:last-child {
				margin-right: 0;
			}
			

			&.active {
				a,
				span {
					background-color: #727272;
					color: #fff;
				}
			}

			a ,
			span {
				display: inline-block;
				border: 2px solid #727272;
				min-width: 30px;
				min-height: 30px;
				color: #727272;
				font-weight: 600;
			}

			a {
				padding: 5px 10px;
			}

			span {
				padding: 5px 15px;
			}
		}
	}
}