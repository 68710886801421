.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	z-index: 20;
	margin-left: auto;
	margin-right: auto;
	max-height: 111px;
	overflow: hidden;
	@media(max-width: 1500px) {
		max-height: 110px;
	}
	@media(max-width: 1380px) {
		// padding: 0 15px;
		// max-height: none;
		position: static;
	}

	&__main {
		display: flex;
		justify-content: space-between;
		max-width: 1772px;
		padding: 0 15px;
		margin-left: auto;
		margin-right: auto;
		@media(max-width: 1760px) {
			max-width: 1500px;
		}
		@media(max-width: 1500px) {
			max-width: 1380px;
		}
		@media(max-width: 1380px) {
			position: fixed;
			max-width: none;
			margin: 0;
			left: 0;
			top: 0;
			bottom: 0;
			width: 320px;
			background-color: #fff;
			box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.3);
			overflow: hidden;
			overflow-y: auto;
			z-index: 101;
			justify-content: flex-start;
			flex-direction: column;
			padding: 38px 0 30px 20px;
			transform: translateX(-350px);
			transition: transform 0.15s ease;

			.header__logo, 
			.header__call {
				display: none;
			}
		}

		&-close {
			position: absolute;
			top: 5px;
			right: 10px;
			width: 28px;
			height: 24px;
			display: none;
			@media(max-width: 1380px) {
				display: block;
			}
			&:hover {
				span {
					&:before,
					&:after {
						background-color: rgb(20, 75, 147);
					}
				}
			}
			span {
				position: relative;
				display: block;
				height: 4px;
				width: 30px;
				background-color: #fff;

				&:before,
				&:after {
					content: '';
					position: absolute;
					display: block;
					width: 30px;
					height: 4px;
					background-color: rgb(31, 31, 31);
					left: 0;
					transition: background-color 0.15s ease;
				}

				&:before {
					top: 0;
					transform: rotateZ(45deg);
				}

				&:after {
					bottom: 0;
					transform: rotateZ(-45deg);
				}
			}
		}
	}

	&__lt,
	&__rt {
		max-width: 757px;
	}

	&__rt {
		display: flex;
		align-items: center;
		@media(max-width: 1380px) {
			display: block;
			margin-top: 15px;
		}

		.header__call {
			margin-left: 104px;
			@media(max-width: 1760px) {
				margin-left: 60px;
			}
		}
	}

	&__list {
		font-size: 14px;

		@media(max-width: 1500px) {
			font-size: 13px;
		}

		li {
			display: inline-block;

			@media(max-width: 1380px) {
				display: block;
			}

			&:hover,
			&.active {
				a {
					border-color: #144b93;
					color: rgb(20, 75, 147);
					text-shadow: 1px 0 rgb(20, 75, 147);
				}
			}

			&:nth-child(n + 2) {
				margin-left: 22px;
				@media(max-width: 1760px) {
					margin-left: 8px;
				}
				@media(max-width: 1380px) {
					margin-left: 0;
					margin-top: 15px;
				}
			}

			a {
				display: block;
				padding-top: 41px;
				padding-bottom: 50px;
				color: rgb(31, 31, 31);
				border-top: 4px solid transparent;
				letter-spacing: 1.1px;
				transition: border-color 0.15s ease, color 0.15s ease, text-shadow 0.15s ease;

				@media(max-width: 1380px) {
					padding: 5px 15px 5px 0;
					border-top: none;
					border-right: 4px solid transparent;
				}
			}
		}
	}

	&__logo {
		position: absolute;
		top: 9px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 20;

		@media(max-width: 1380px) {
			top: 0;
		}

		@media(max-width: $sw-sm-max) {
			max-width: 170px;
		}

		@media(max-width: $sw-xx-max) {
			display: none;
		}

		&_xx {
			display: none;

			@media(max-width: $sw-xx-max) {
				display: block;
			}
		}
	}

	&__call {
		display: inline-block;
		font-size: 26px;
		font-family: 'Roboto';
		font-weight: bold;
		letter-spacing: 2px;
		@media(max-width: 1500px) {
			font-size: 20px;
			padding-bottom: 6px;
		}
		@media(max-width: $sw-xs-max) {
			font-size: 26px;
		}

		&:hover {
			a {
				color: rgb(20, 75, 147);
				transition: color 0.15s ease;
			}
		}

		a {
			svg {
				display: none;
				@media(max-width: $sw-xs-max) {
					display: block;
				}
			}
			span {
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			
		}
	}

	&__phone {
		display: none;
		justify-content: space-between;
		align-items: center;
		height: 94px;
			
		@media(max-width: 1380px) {
			display: flex;
			padding: 0 15px;
			z-index: 49;
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			background-color: #fff;
		}

		@media(max-width: $sw-sm-max) {
			height: 72px;
		}

		@media(max-width: $sw-xx-max) {
			height: 70px;
		}

		&-menu {
			&-open {
				width: 40px;
				height: 18px;

				&:hover {
					span {
						background-color: rgb(20, 75, 147);
						&:before,
						&:after {
							background-color: rgb(20, 75, 147);
						}
						&:before {
							top: -9px;
						}
						&:after {
							bottom: -9px;
						}
					}
				}
				span {
					position: relative;
					display: block;
					height: 4px;
					width: 100%;
					background-color: rgb(31, 31, 31);
					transition: background-color 0.15s ease;

					&:before,
					&:after {
						content: '';
						position: absolute;
						display: block;
						width: 100%;
						height: 4px;
						background-color: rgb(31, 31, 31);
						left: 0;
						transition: top 0.15s ease, bottom 0.15s ease, background-color 0.15s ease;
					}

					&:before {
						top: -7px;
					}

					&:after {
						bottom: -7px;
					}
				}
			}
		}
	}
}

.menu-open {
	overflow: hidden;
	height: 100vh;

	.header__main {
		transform: translateX(0);
	}

	.header__phone-menu-open {
		
	}

	.close-menu-field {
		opacity: 1;
		visibility: visible;
	}
}

.close-menu-field {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 50;
	background-color: rgba(18, 20, 21, 0.7);
	opacity: 0;
	visibility: hidden;
	transition: all 0.15s ease;
}