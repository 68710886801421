html {
	height: 100%;
	font-size: 10px;
	// -webkit-transform: translate3d(0,0,0);
}

body {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;

	@media(max-width: $sw-sm-max) {
		font-size: 13px;
	}
}

img{
	max-width: 100%;
	height: auto;
}

p {
	margin-bottom: 0;
	letter-spacing: 0.47px;
}

button {
	cursor: pointer;
	outline: none !important;
}

h2 {
	font-size: 72px;
	margin-bottom: 0;
	line-height: 0.8;
	letter-spacing: 4.1px;
	@media(max-width: $sw-md-max) {
		font-size: 60px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 50px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 32px;
	}
}

h3,
h4 {
	margin: 0;
}

h3 {
	letter-spacing: 1.8px;
}

h4 {
	font-size: 16px;
	letter-spacing: 1.3px;

	@media(max-width: $sw-xs-max) {
		font-size: 15px;
	}
}

.h-list {
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0;
}

.font-blue {
	color: rgb(20, 75, 147)
}

.text-upp {
	text-transform: uppercase;
}

.fs-130 {
	font-size: 130px;
	@media(max-width: $sw-md-max) {
		font-size: 105px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 75px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 50px;
	}
}

.fs-120 {
	font-size: 120px;
	@media(max-width: $sw-md-max) {
		font-size: 95px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 70px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 46px;
	}
}

.fs-110 {
	font-size: 110px;
	@media(max-width: $sw-md-max) {
		font-size: 88px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 62px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 42px;
	}
}

.fs-100 {
	font-size: 100px;
	@media(max-width: $sw-md-max) {
		font-size: 82px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 58px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 38px;
	}
}

.fs-90 {
	font-size: 90px;
	@media(max-width: $sw-md-max) {
		font-size: 70px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 55px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 36px;
	}
}

.fs-72 {
	font-size: 72px;
	@media(max-width: $sw-md-max) {
		font-size: 60px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 50px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 32px;
	}
}

.fs-70 {
	font-size: 70px;
	@media(max-width: $sw-md-max) {
		font-size: 60px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 50px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 32px;
	}
}

.fs-62 {
	font-size: 62px;
	@media(max-width: $sw-md-max) {
		font-size: 53px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 43px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 25px;
	}
}

.fs-60 {
	font-size: 60px;
	@media(max-width: $sw-md-max) {
		font-size: 52px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 42px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 24px;
	}
}

.fs-36 {
	font-size: 36px;
	@media(max-width: $sw-md-max) {
		font-size: 28px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 24px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 20px;
	}
}

.fs-31 {
	font-size: 31px;
	@media(max-width: $sw-md-max) {
		font-size: 26px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 22px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 18px;
	}
}

.fs-24 {
	font-size: 24px;
	@media(max-width: $sw-md-max) {
		font-size: 20px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 18px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 16px;
	}
}

.fs-18 {
	font-size: 18px;
	@media(max-width: $sw-md-max) {
		font-size: 17px;
	}
	@media(max-width: $sw-sm-max) {
		font-size: 16px;
	}
	@media(max-width: $sw-xs-max) {
		font-size: 14px;
	}
}

.block-title {
	color: #144b93;
    letter-spacing: 2px;
}

.font-sansita {
	font-family: 'Sansita';
}

.page-preloader{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff;
	z-index: 99999;
	transition: all 0.15s ease;
	&.load{
		opacity: 0;
		visibility: hidden;
	}
}

.loader {
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	width:80px;
	height:80px;

    span {
		border:0;
		margin:0;
		width:40%;
		height:40%;
		position:absolute;
		border-radius:50%;
		animation:load 2s ease infinite;

	}
	:first-child {
		background:#144b93;
		animation-delay:-1.5s;
	}
	:nth-child(2){
		background:#939597;
		animation-delay:-1s;
	}
	:nth-child(3){
		background:#144b93;
		animation-delay:-0.5s;
	}
	:last-child{
		background:#939597;
	}
}

@keyframes load {
  0% {
  	transform:translate(0);
  }
  25% {
  	transform:translate(160%);
  }
  50% {
  	transform:translate(160%, 160%);
  }
  75%{
  	transform:translate(0, 160%);
  }
  100% {
 	transform:translate(0);
  }
}

@-o-keyframes load {
  0% {
  	transform:translate(0);
  }
  25% {
  	transform:translate(160%);
  }
  50% {
  	transform:translate(160%, 160%);
  }
  75%{
  	transform:translate(0, 160%);
  }
  100% {
 	transform:translate(0);
  }
}

@-ms-keyframes load {
  0% {
  	transform:translate(0);
  }
  25% {
  	transform:translate(160%);
  }
  50% {
  	transform:translate(160%, 160%);
  }
  75%{
  	transform:translate(0, 160%);
  }
  100% {
 	transform:translate(0);
  }
}

@-webkit-keyframes load {
  0% {
  	transform:translate(0);
  }
  25% {
  	transform:translate(160%);
  }
  50% {
  	transform:translate(160%, 160%);
  }
  75%{
  	transform:translate(0, 160%);
  }
  100% {
 	transform:translate(0);
  }
}

@-moz-keyframes load {
  0% {
  	transform:translate(0);
  }
  25% {
  	transform:translate(160%);
  }
  50% {
  	transform:translate(160%, 160%);
  }
  75%{
  	transform:translate(0, 160%);
  }
  100% {
 	transform:translate(0);
  }
}