// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Custom Color system
// $scorpion: #585858;
// Color system
// Options
// Spacing
// Body
  //
  // Settings for the `<body>` element.

  $body-bg:       $white;
  $body-color:    rgb(0, 0, 0);

// Links
  //
  // Style anchor elements.
  $link-color: #000;
  $link-hover-color: #000;

  $link-decoration:       none;
  $link-hover-decoration: none;

// Grid breakpoints
// Grid columns
// Fonts
$font-family-sans-serif: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif:      'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, serif;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:       $font-family-sans-serif;
//
// Font, line-height, and color for body text, headings, and more.

$headings-font-family: $font-family-sans-serif;

$font-size-base: 14px; // Assumes the browser default, typically `14px`

$line-height-base: 1.2;
// $h1-font-size: 3.6rem;
// $h2-font-size: 2.6rem;
// $h3-font-size: 2.3rem;
// $h4-font-size: 2rem;
// $h5-font-size: 1.8rem;
// $h6-font-size: 1.6rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$sw-xx-min:0;//0
$sw-xs-min:576px;//0//576
$sw-sm-min:768px;//576//768
$sw-md-min:992px;//768
$sw-lg-min:1230px;//1017

$sw-xx-max:$sw-xs-min - 1px;//479px
$sw-xs-max:$sw-sm-min - 1px;
$sw-sm-max:$sw-md-min - 1px;
$sw-md-max:$sw-lg-min - 1px;

$grid-breakpoints: (
  xs: $sw-xs-min,
  sm: $sw-sm-min,
  md: $sw-md-min,
  lg: $sw-lg-min,
);

$grid-columns:               24;
$grid-gutter-width-base:     30px;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
);
// //@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// //@include _assert-starts-at-zero($grid-breakpoints);


// // Grid containers
// //
// // Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  md: 960px,
  lg: 1200px,
);
//@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// $grid-columns:               24;
// $grid-gutter-width-base:     30px;
// $grid-gutter-widths: (
//   xs: $grid-gutter-width-base,
//   sm: $grid-gutter-width-base,
//   md: $grid-gutter-width-base,
//   lg: $grid-gutter-width-base,
// );