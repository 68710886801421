.section-testimonials {
	padding: 99px 0 67px;
	@media(max-width: $sw-md-max) {
		padding: 70px 0 57px;
	}
	@media(max-width: $sw-sm-max) {
		padding: 55px 0;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}
}

.testimonials {
	&__decoration {
		&-img {
			&-1 {
				left: -318px;
				top: 36.9%;
				@media(max-width: $sw-md-max) {
					left: -170px;
				}
				@media(max-width: $sw-xx-max) {
					display: none;
				}
			}

			&-2 {
				right: -217px;
				top: 34.2%;
				@media(max-width: $sw-md-max) {
					right: -160px;
				}
				@media(max-width: $sw-xx-max) {
					display: none;
				}
			}
		}
	}
	&__wrap {
		width: 940px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		.section-pagination {
			margin-top: 60px;
			@media(max-width: $sw-md-max) {
				margin-top: 45px;
			}
			@media(max-width: $sw-sm-max) {
				margin-top: 35px;
			}
			@media(max-width: $sw-xs-max) {
				margin-top: 25px;
			}
			@media(max-width: $sw-xx-max) {
				text-align: center;
			}
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		@media(max-width: $sw-xx-max) {
			justify-content: center;
		}
	}

	&__item {
		width: 300px;
		max-width: 100%;
		background-color: #e5e5e5;
		margin-right: 20px;
		max-width: 300px;

		@media(max-width: $sw-md-max) {
			width: 296px;
		}
		@media(max-width: $sw-sm-max) {
			width: 49%;
			margin-right: 0;
		}
		@media(max-width: $sw-xx-max) {
			width: 100%;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}

		&:nth-child(n + 4) {
			margin-top: 22px;
		}

		&:nth-child(2n) {
			@media(max-width: $sw-sm-max) {
				margin-left: 2%;
			}
			@media(max-width: $sw-xx-max) {
				margin-left: 0;
			}
		}

		&:nth-child(n + 3) {
			@media(max-width: $sw-sm-max) {
				margin-top: 15px;
			}
			@media(max-width: $sw-xs-max) {
				margin-top: 10px;
			}
		}

		&:nth-child(n + 2) {
			@media(max-width: $sw-xx-max) {
				margin-top: 15px;
			}
		}

		&-video {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			height: 0;
			z-index: 0;
			padding-bottom: 200px;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			&-wrap {
				position: relative;
				height: 200px;
				background-color: #bfbfbf;

				.testimonials__item-img {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					cursor: pointer;
					z-index: 1;
					transform: scale(1);
					transition: all 0.15s ease;

					&.no-active {
						opacity: 0;
						visibility: hidden;
						transform: scale(0.1);
					}

					&:hover {
						.testimonials__item-play {
							color: #144b93;
						}
					}
				}
			}
		}

		&-img {
			position: relative;
			height: 200px;
			background-color: #bfbfbf;

			&-1,
			&-2 {
				height: 100%;
				width: 100%;
				img,
				div {
					width: 100%;
					height: 100%;
				}

				span {
					position: absolute;
					text-transform: uppercase;
					color: #fff;
					font-weight: 600;
					bottom: 21px;
					letter-spacing: 1px;
				}
			}

			&-1 {
				z-index: 1;
				span {
					left: 19px;
				}
			}

			&-2 {
				span {
					right: 19px;
				}
			}

			&.cocoen {
				img {
					height: 100%;
					width: 100%;
				}
				.cocoen-drag {
					width: 3px;
					background: #bfbfbf;
					z-index: 2;

					&:before {
						background: url(../images/useful/testimonials/cocoen-drag.png) no-repeat;
						border: none;
						width: 33px;
						height: 33px;
						z-index: 2;
						left: 1px;
						right: 0;
						top: 50%;
						transform: translate(-50%, -50%);
						margin: 0;
					}
				}
			}
		}

		&-play {
			display: flex;
			position: absolute;
			bottom: 11px;
			left: 14px;
			opacity: 0.7;
			color: #1a1a1a;
			font-size: 62px;
			transition: color 0.15s ease;
		}

		&-info {
			padding: 30px 18px 52px 18px;

			@media(max-width: $sw-xs-max) {
				padding: 18px 14px 30px 14px;
			}

			&-rating {
				display: flex;
			}

			&-ttl {
				margin-top: 12px;
			}

			&-about {
				font-size: 11px;
				color: rgb(104, 104, 104);
				font-weight: 600;
				margin-top: 7px;
				letter-spacing: 0.8px;
				line-height: 1.65;
			}

			&-descr {
				font-size: 13px;
				margin-top: 16px;
				letter-spacing: 0.99px;
				line-height: 1.231;

				@media(max-width: $sw-xs-max) {
					margin-top: 10px;
				}
			}

			&-link {
				font-weight: bold;
				margin-top: 13px;
				letter-spacing: 1px;
				a {
					color: rgb(20, 75, 147);
					transition: color 0.15s ease;

					&:hover {
						color: rgb(20, 75, 147) + 30%;
					}
				}
			}
		}
	}
}

.education {
	
	&__decoration {
		&-img {
			&-1 {
				right: -5.8%;
				top: -8.5%;
				@media(max-width: $sw-md-max) {
					right: -5%;
					top: -7%;
				}
				@media(max-width: $sw-md-max) {
					right: 4%;
					top: -5%;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			&-2 {
				top: 31.6%;
				left: -30.1%;
				@media(max-width: $sw-md-max) {
					left: -20%;
				}
				@media(max-width: $sw-sm-max) {
					left: -25%;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
		}
	}

	&__item {
		.testimonials__item-info {
			padding: 6px 18px 37px 18px;
			@media(max-width: $sw-xs-max) {
				padding: 6px 14px 30px 14px;
			}
		}
		.testimonials__item-info-about {
			margin-top: 2px;
		}
	}
}

.rating-star {
	font-size: 16px;
	display: flex;

	&:nth-child(n + 2) {
		margin-left: 5px;
	}

	&_fill {
		color: #fff474;
	}
	&_empty {
		color: #fff;
	}
}