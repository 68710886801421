.section-inside {
	padding: 200px 0 155px;
	background: url(../images/useful/home/inside-bg.jpg) no-repeat center;
	background-size: cover;
	background-position: 50% 0;
	position: relative;
	background-attachment: fixed;
	@media(max-width: $sw-md-max) {
		padding: 180px 0 130px;
	}
	@media(max-width: $sw-sm-max) {
		padding: 140px 0 100px;
	}
	@media(max-width: $sw-xs-max) {
		padding: 100px 0 80px;
	}
	@media(max-width: $sw-xx-max) {
		padding: 80px 0 60px
	}
}

.inside {
	&__title {
		display: flex;
		align-items: center;
		flex-direction: column;
		color: #fff;

		span {
			&:first-child {
				letter-spacing: 2.8px;
			}
			&:last-child {
				line-height: 1;
				margin-top: 4px;
				letter-spacing: 5px;
			}
		}
	}

	&__link {
		margin-top: 39px;
		text-align: center;
		@media(max-width: $sw-sm-max) {
			margin-top: 25px;
		}
		@media(max-width: $sw-xs-max) {
			margin-top: 18px;
		}
	}
}
