.section-contacts {
	display: flex;
	justify-content: flex-end;
	padding: 94px 0 81px;
	@media(max-width: $sw-md-max) {
		padding: 75px 0;
	}
	@media(max-width: $sw-sm-max) {
		padding: 60px 0;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}
}

.contacts {
	&__wrap {
		width: 1470px;
		max-width: 100%;
		padding-left: 20px;
		display: flex;
		justify-content: space-between;

		@media(max-width: $sw-md-max) {
			justify-content: flex-end;
		}
		@media(max-width: $sw-sm-max) {
			flex-direction: column;
			padding-left: 0;
		}
	}

	&__lt {
		width: 360px;
		margin-right: 20px;
		max-width: 100%;
		@media(max-width: $sw-sm-max) {
			padding: 0 15px;
			margin: 0 auto;
			width: 100%;
		}
	}
	
	&__rt {
		width: 1050px;
		max-width: 100%;
		min-height: 300px;
		@media(max-width: $sw-md-max) {
			width: 700px;
		}
		@media(max-width: $sw-sm-max) {
			width: 100%;
			height: 400px;
			margin-top: 25px;
		}
		@media(max-width: $sw-xs-max) {
			height: 300px;
		}
	}

	&__address {
		&-ttl {
			letter-spacing: 1.8px;
		}
		&-info,
		&-call {
			font-size: 16px;
			color: rgb(31, 31, 31);
			line-height: 1.5;
			font-weight: 500;
			margin-top: 20px;
			letter-spacing: 1.15px;
			@media(max-width: $sw-sm-max) {
				margin-top: 12px;
				font-size: 13px;
			}
		}

		&-call {
			span {
				font-weight: 600;
			}
		}
	}

	&__form {
		margin-top: 40px;
		max-width: 320px;
		@media(max-width: $sw-sm-max) {
			max-width: none;
			margin-top: 20px;
		}

		&-inputs {
			margin-top: 24px;
			@media(max-width: $sw-sm-max) {
				margin-top: 15px;
			}

			.form-group {
				&:nth-child(n + 2) {
					margin-top: 15px;
				}
			}
		}
	}
}