.section-gallery {
	padding: 80px 0 99px;
	@media(max-width: $sw-md-max) {
		padding: 75px 0;
	}
	@media(max-width: $sw-sm-max) {
		padding: 60px 0;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}
}

.gallery {
	&__decoration {
		&-office {
			&-img {
				&-1 {
					left: -24%;
					top: 1.9%;
					@media(max-width: $sw-md-max) {
						left: -20%;
					}
					@media(max-width: $sw-sm-max) {
						display: none;
					}
				}
				&-2 {
					top: 51.6%;
					right: -25%;
					@media(max-width: $sw-md-max) {
						right: -20%;
					}
					@media(max-width: $sw-sm-max) {
						display: none;
					}
				}
			}
		}
		&-team {
			&-img {
				&-1 {
					left: -16%;
					top: -0.7%;
					@media(max-width: $sw-sm-max) {
						display: none;
					}
				}
				&-2 {
					right: -16.3%;
					top: 40.4%;
					@media(max-width: $sw-sm-max) {
						display: none;
					}
				}
				&-3 {
					bottom: -8.7%;
					left: -13%;
					@media(max-width: $sw-sm-max) {
						display: none;
					}
				}
			}
		}
	}
	&__item {
		display: flex;
		flex-direction: column;

		&:nth-child(n + 2) {
			margin-top: 19px;
			@media(max-width: $sw-sm-max) {
				margin-top: 15px;
			}
		}
		&-line {
			display: flex;
			@media(max-width: $sw-xx-max) {
				flex-direction: column;
			}

			> * {
				&:nth-child(n + 2) {
					margin-left: 20px;
					@media(max-width: $sw-sm-max) {
						margin-left: 15px;
					}
					@media(max-width: $sw-xx-max) {
						margin-left: 0;
						margin-top: 15px;
					}
				}
			}

			&:nth-child(n + 2) {
				margin-top: 19px;
				@media(max-width: $sw-sm-max) {
					margin-top: 15px;
				}
			}

			&-video {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				height: 0;
				z-index: 0;
				height: 100%;
				
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				&-wrap {
					position: relative;
					background-color: #bfbfbf;

					.gallery__item-line-img {
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						z-index: 1;
						cursor: pointer;
						transform: scale(1);
						transition: all 0.15s ease;

						&.no-active {
							opacity: 0;
							visibility: hidden;
							transform: scale(0.1);
						}
					}
					

					&:hover {
						.gallery__item-line-video-play {
							color: #144b93;
						}
					}
				}

				&-play {
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					margin: auto;
					font-size: 150px;
					height: 150px;
					width: 150px;
					@media(max-width: $sw-sm-max) {
						font-size: 80px;
						width: 80px;
						height: 80px;
					}
				}
			}

			&-img {
				height: 100%;
				// opacity: 0;
				
				img {
					transition: transform 1s ease-in-out;
				}

				&:hover {
					img {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}
.item-h-483 {
	height: 483px;
	@media(max-width: $sw-sm-max) {
		height: 425px;
	}
	@media(max-width: $sw-xs-max) {
		height: 300px;
	}
}
.item-h-470 {
	height: 470px;
	@media(max-width: $sw-sm-max) {
		height: 420px;
	}
	@media(max-width: $sw-xs-max) {
		height: 300px;
	}
}
.item-h-300 {
	height: 300px;
	@media(max-width: $sw-sm-max) {
		height: 260px;
	}
	@media(max-width: $sw-xs-max) {
		height: 180px;
	}
}

.item-h-300,
.item-h-470 {
	@media(max-width: $sw-xx-max) {
		height: 250px;
	}
}

.item-w-620 {
	width: 620px;
}
.item-w-460 {
	width: 460px;
}
.item-w-300 {
	width: 300px;
}

.item-w-620,
.item-w-460,
.item-w-300 {
	max-width: 100%;
	@media(max-width: $sw-xx-max) {
		width: 100%;
	}
}

.animateIn {
	animation-name: animateIn;
	animation-duration: 1.5s;
	animation-timing-function: ease-in;
}

@keyframes animateIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}