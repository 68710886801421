.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;

	&-facebook {
		width: 0.46663566612415713em;
	}
	&-google-plus {
		width: 1.303046357615894em;
	}
	&-youtube {
		width: 1.4207149404216317em;
	}
	&-twitter {
		width: 1.2311406155703077em;
	}

	&-service {
		&-1 {
			width: 0.984375em;
		}
		&-2 {
			width: 1.171875em;
		}
		&-3 {
			width: 1.0819672131147542em;
		}
		&-4 {
			width: 0.828125em;
		}
		&-5 {
			width: 0.6366459627329192em;
		}
		&-6 {
			width: 1.8205128205128205em;
		}
		&-7,
		&-8 {
			width: 0.7808764940239044em;
		}
		&-9 {
			width: 0.890625em;
		}
		&-10 {
			width: 1.2313461538461539em;
		}
		&-11 {
			width: 0.5915492957746479em;
		}
		&-12 {
			width: 0.741969696969697em;
		}
		&-13 {
			width: 1.0813438612788355em;
		}
		&-14 {
			width: 1.2378054781039856em;
		}
		&-15 {
			width: 1.0666666666666667em;
		}
		&-16 {
			width: 0.7808764940239044em;
		}
		&-17 {
			width: 0.96296875em;
		}
		&-18 {
			width: 0.9848484848484849em;
		}
	}
}
	
/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
	font-size: 32px;
	color: red;
}
========================================== */
	