.section-offers {
	padding: 241px 0 299px;
	@media(max-width: $sw-md-max) {
		padding: 180px 0;
	}
	@media(max-width: $sw-sm-max) {
		padding: 100px 0 60px;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}
}

.offers {
	&__wrap {
		position: relative;
	}
	&__decoration {
		&-img {
			&-1 {
				top: -188px;
				left: 50%;
				transform: translateX(-50%);
				@media(max-width: $sw-md-max) {
					top: -160px;
				}
				@media(max-width: $sw-sm-max) {
					top: -105px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			&-2 {
				bottom: -306px;
				left: 52.5%;
				transform: translateX(-50%);
				@media(max-width: $sw-md-max) {
					bottom: -260px;
				}
				@media(max-width: $sw-sm-max) {
					display: none;
				}
			}
		}
	}
	&__items {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	&__item {
		display: flex;
		background-color: #e6e6e6;
		width: 620px;
		max-width: 100%;
		box-shadow: 0px 17px 38.4px 1.6px rgba(0, 0, 0, 0.31);

		@media(max-width: $sw-xx-max) {
			flex-direction: column;
			box-shadow: 0px 8px 26.4px 0.6px rgba(0, 0, 0, 0.31);
		}

		&:nth-child(n + 2) {
			margin-top: 69px;
			@media(max-width: $sw-sm-max) {
				margin-top: 55px;
			}
			@media(max-width: $sw-xs-max) {
				margin-top: 45px;
			}
			@media(max-width: $sw-xx-max) {
				margin-top: 30px;
			}
		}

		&-img {
			min-height: 200px;
			width: 299px;
			flex-shrink: 0;

			@media(max-width: $sw-xs-max) {
				width: 250px;
			}
			@media(max-width: $sw-xx-max) {
				width: 100%;
			}
		}
		&-info {
			padding: 26px 21px 31px;
			text-align: center;
			width: 100%;
			@media(max-width: $sw-xs-max) {
				padding: 15px 15px 20px;
			}

			&-price {
				color: rgb(16, 68, 162);
				letter-spacing: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				&-old {
					color: rgb(102, 102, 102);
					text-decoration: line-through;
				}

				span {
					&:nth-child(n + 2) {
						margin-left: 12px;
					}
				}
			}
			&-ttl {
				margin-top: 12px;
				@media(max-width: $sw-xs-max) {
					margin-top: 8px;
				}

				.card-title {
					line-height: 1;
				}
			}
			&-about {
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 1.2px;
				margin-top: 7px;
				@media(max-width: $sw-xs-max) {
					font-size: 14px;
					margin-top: 5px;
				}
			}

			&-descr {
				letter-spacing: 0.5px;
				margin-top: 9px;
				font-size: 13px;
				line-height: 1.4;
				font-weight: 500;
				@media(max-width: $sw-xs-max) {
					margin-top: 7px;
				}
			}
			&-link {
				margin-top: 18px;
				@media(max-width: $sw-xs-max) {
					margin-top: 12px;
				}
			}
		}
	}
}
