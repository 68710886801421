button{
	// button reset
	display: inline-block;
	margin: 0;
	padding: 0;
	border: 0;
	background-color: transparent;
}
.btn{
	display: inline-block;
	height: 59px;
	border-radius: 30px;
	font-weight: 500;
	line-height: 59px;
	padding: 0 15px;
	font-size: 14px;
	letter-spacing: 2.5px;
	border: none;
	background-color: transparent;
	color: inherit;
	width: 100%;
	max-width: 230px;
	color: #fff;
	overflow: hidden;
	text-transform: uppercase;
	outline: none;
	transition: all 150ms ease;

	@media(max-width: $sw-sm-max) {
		font-size: 13px;
		height: 50px;
		line-height: 50px;
	}

	span {
		position: relative;
		z-index: 2;
		text-shadow: 0px 1px 1.96px rgba(0, 0, 0, 0.61);
	}

	&:hover{
		color: #fff;
	}
	&:focus,
	&:active{
		box-shadow: none;
		// border-color: @White40;
		// background-color: @Concrete;
		// color: @CapeCod;
	}
	// colors
	&_blue {
		position: relative;
		background-image: -moz-linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);
		background-image: -webkit-linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);
		background-image: -ms-linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);
		background-image: linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);

		&:after {
			content: '';
			position: absolute;
			opacity: 0;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
			border-radius: 30px;
			background-image: -moz-linear-gradient(to bottom, #1b75ca + 20% 0%, #1043a1 + 10% 100%);
			background-image: -webkit-linear-gradient(to bottom, #1b75ca + 20% 0%, #1043a1 + 10% 100%);
			background-image: -ms-linear-gradient(to bottom, #1b75ca + 20% 0%, #1043a1 + 10% 100%);
			background-image: linear-gradient(to bottom, #1b75ca + 20% 0%, #1043a1 + 10% 100%);
			transition: opacity 0.15s ease;
		}

		&:hover {
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	// states
	// a&{
	//     text-decoration: none;
	// }
	&_icon{
	}
	// sizes
	&_md{
	}
	&_sm{
		height: 44px;
		line-height: 44px;
		font-size: 12px;
		max-width: 200px;
		@media(max-width: $sw-sm-max) {
			height: 36px;
			line-height: 36px;
		}
	}
	&_xs{
	}
}