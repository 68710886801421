.section-services-page {
	padding: 100px 0 87px;
	@media(max-width: $sw-md-max) {
		padding: 80px 0 70px;
	}
	@media(max-width: $sw-sm-max) {
		padding: 60px 0 50px;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0 30px;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}
}

.services-page {
	&__wrap {
		width: 980px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}

	&__decoration {
		&-img {
			&-1 {
				left: -281px;
				top: 180px;
				@media(max-width: $sw-md-max) {
					left: -200px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			&-2 {
				right: -266px;
				top: 44.8%;
				@media(max-width: $sw-md-max) {
					right: -206px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
			&-3 {
				bottom: -56px;
				left: -248px;
				@media(max-width: $sw-md-max) {
					left: -188px;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
		}
	}

	&__cards {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__card {
		display: flex;
		position: relative;
		overflow: hidden;
		min-height: 185px;
		background-color: #f1f1f1;
		width: 480px;
		box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
		padding: 21px 20px 25px 53px;

		@media(max-width: $sw-md-max) {
			width: 455px;
			padding-left: 40px;
		}

		@media(max-width: $sw-sm-max) {
			width: 335px;
			padding: 15px 15px 18px 20px;
			min-height: 160px;
		}

		@media(max-width: $sw-xs-max) {
			padding: 15px;
			width: 245px;
		}

		@media(max-width: $sw-xx-max) {
			width: 100%;
			min-height: none;
		}

		&:nth-child(n + 3) {
			margin-top: 19px;
			@media(max-width: $sw-xs-max) {
				margin-top: 15px;
			}
		}

		&:nth-child(n + 2) {
			@media(max-width: $sw-xx-max) {
				margin-top: 15px;
			}
		}

		&-wrap {
			display: flex;
			align-items: center;
			position: relative;
			@media(max-width: $sw-xs-max) {
				flex-direction: column;
			}
		}

		&:hover {
			&:before {
				width: 100%;
			}
			.services-page__card-icon {
				transform: scale(1.032) translateZ(0);
			}
		}

		&:before {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 0;
			height: 100%;
			background: rgba(255,255,255,0.5);
			content: '';
			transition: width 0.35s ease;
		}

		&-about {
			margin-left: 34px;
			@media(max-width: $sw-sm-max) {
				margin-left: 20px;
			}
			@media(max-width: $sw-xs-max) {
				margin-left: 0;
				margin-top: 15px;
			}
		}
		&-descr {
			margin-top: 8px;
			max-height: 48px;
			overflow: hidden;
			@media(max-width: $sw-sm-max) {
				margin-top: 5px;
				max-height: 45px;
			}
			@media(max-width: $sw-xx-max) {
				max-height: 30px;
			}
		}
		&-link {
			margin-top: 20px;
			@media(max-width: $sw-sm-max) {
				margin-top: 15px;
			}
			@media(max-width: $sw-xs-max) {
				margin-top: 10px;
			}
		}
		&-icon {
			height: auto;
			color: #1c5197;
			flex-shrink: 0;
			width: 72px;
			display: flex;
			justify-content: center;
			overflow: hidden;
			transform: translateZ(0);
			transition: transform 0.75s ease;
			@media(max-width: $sw-sm-max) {
				width: 55px;
			}
			@media(max-width: $sw-xx-max) {
				font-size: 40px;
				width: auto;
			}
		}
	}
}


.card {
	&-title { 
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 500;
		letter-spacing: 1.5px;
		line-height: 1.3;
		@media(max-width: $sw-sm-max) {
			font-size: 16px;
		}
		@media(max-width: $sw-xs-max) {
			font-size: 15px;
		}
	}
	&-link {
		font-size: 14px;
		color: rgb(20, 75, 147);
		font-weight: 600;
		letter-spacing: 1px;
		@media(max-width: $sw-sm-max) {
			font-size: 13px;
		}
	}
	&-text {
		font-size: 13px;
		letter-spacing: 1px;
    	line-height: 1.3;
    	@media(max-width: $sw-sm-max) {
			font-size: 12px;
		}
	}
}