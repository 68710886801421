.section-insurance {
	padding: 77px 0 46px;

	@media(max-width: $sw-md-max) {
		padding: 70px 0 30px;
	}
	@media(max-width: $sw-sm-max) {
		padding: 60px 0 30px;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0 30px;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}
}

.insurance {
	&__text {
		max-width: 563px;
		margin: 0 auto;
		position: relative;

		p {
			line-height: 1.286;
			letter-spacing: 1.05px;
			&:nth-child(n + 2) {
				margin-top: 18px;
				@media(max-width: $sw-sm-max) {
					margin-top: 12px;
				}
			}
		}

		&-decoration {
			&-img-1 {
				left: -32%;
				top: 22%;
				@media(max-width: $sw-md-max) {
					left: -19%;
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
		}
	}

	&__companies {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		
		&-wrap {
			position: relative;
			margin-top: 84px;
			padding-bottom: 220px;
			@media(max-width: $sw-md-max) {
				padding-bottom: 170px;
				margin-top: 60px;
			}
			@media(max-width: $sw-sm-max) {
				padding-bottom: 135px;
				margin-top: 50px;
			}
			@media(max-width: $sw-xs-max) {
				padding-bottom: 0;
			}
		}

		&-decoration {
			&-img-1 {
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				@media(max-width: $sw-sm-max) {
					transform: translateX(-50%) scale(0.6);
				}
				@media(max-width: $sw-xs-max) {
					display: none;
				}
			}
		}
	}

	&__company {
		width: 220px;
		height: 110px;
		max-width: 100%;
		border: 1px solid rgb(232, 232, 232);
		background-color: #fff;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin-right: 20px;
		margin-bottom: 20px;
		transition: border-color 0.4s ease;

		&:hover {
			border-color: #144b93;
		}

		@media(max-width: $sw-sm-max) {
			width: 216px;
		}

		@media(max-width: $sw-xs-max) {
			width: 220px;
		}

		@media(max-width: $sw-xx-max) {
			width: 46%;
		}

		@media(max-width: 450px) {
			width: 220px;
			margin-right: 0;
		}

		&:nth-child(4n),
		&:last-child {
			margin-right: 0;
		}

		&:nth-child(4n) {
			@media(max-width: $sw-md-max) {
				margin-right: 20px;
			}
		}

		&:nth-child(3n) {
			@media(max-width: $sw-md-max) {
				margin-right: 0;
			}
			@media(max-width: $sw-xs-max) {
				margin-right: 20px;
			}
			@media(max-width: 450px) {
				margin-right: 0;
			}
		}

		&:nth-child(2n) {
			@media(max-width: $sw-xs-max) {
				margin-right: 0;
			}
		}

		&-img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}