html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  color: black;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #000;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f8f9fa;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #212529; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1230px) {
    .container {
      max-width: 1200px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24, .col-xs,
.col-xs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 576px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xs-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xs-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xs-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xs-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xs-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xs-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xs-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xs-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xs-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xs-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xs-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xs-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xs-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xs-1 {
    order: 1; }
  .order-xs-2 {
    order: 2; }
  .order-xs-3 {
    order: 3; }
  .order-xs-4 {
    order: 4; }
  .order-xs-5 {
    order: 5; }
  .order-xs-6 {
    order: 6; }
  .order-xs-7 {
    order: 7; }
  .order-xs-8 {
    order: 8; }
  .order-xs-9 {
    order: 9; }
  .order-xs-10 {
    order: 10; }
  .order-xs-11 {
    order: 11; }
  .order-xs-12 {
    order: 12; }
  .order-xs-13 {
    order: 13; }
  .order-xs-14 {
    order: 14; }
  .order-xs-15 {
    order: 15; }
  .order-xs-16 {
    order: 16; }
  .order-xs-17 {
    order: 17; }
  .order-xs-18 {
    order: 18; }
  .order-xs-19 {
    order: 19; }
  .order-xs-20 {
    order: 20; }
  .order-xs-21 {
    order: 21; }
  .order-xs-22 {
    order: 22; }
  .order-xs-23 {
    order: 23; }
  .order-xs-24 {
    order: 24; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; } }

@media (min-width: 1230px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e9ecef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e9ecef; }
  .table tbody + tbody {
    border-top: 2px solid #e9ecef; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #e9ecef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e9ecef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.thead-inverse th {
  color: #fff;
  background-color: #212529; }

.thead-default th {
  color: #495057;
  background-color: #e9ecef; }

.table-inverse {
  color: #fff;
  background-color: #212529; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #32383e; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 1229px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: none; }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 14px; }

.form-control-plaintext {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.3125rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #868e96; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.invalid-feedback {
  display: none;
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .invalid-feedback,
  .was-validated .form-control:valid ~ .invalid-tooltip, .form-control.is-valid ~ .invalid-feedback,
  .form-control.is-valid ~ .invalid-tooltip, .was-validated
  .custom-select:valid ~ .invalid-feedback,
  .was-validated
  .custom-select:valid ~ .invalid-tooltip,
  .custom-select.is-valid ~ .invalid-feedback,
  .custom-select.is-valid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  line-height: 1.25;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #0069d9;
    background-image: none;
    border-color: #0062cc; }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #727b84;
    background-image: none;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    background-color: #218838;
    background-image: none;
    border-color: #1e7e34; }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    background-color: #138496;
    background-image: none;
    border-color: #117a8b; }

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    background-color: #e0a800;
    background-image: none;
    border-color: #d39e00; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    background-color: #c82333;
    background-image: none;
    border-color: #bd2130; }

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #111;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    background-color: #e2e6ea;
    background-image: none;
    border-color: #dae0e5; }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    background-color: #23272b;
    background-image: none;
    border-color: #1d2124; }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 3px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 3px rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 3px rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 3px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:active, .btn-outline-light.active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 3px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:active, .btn-outline-dark.active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }

.btn-link {
  font-weight: normal;
  color: #000;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent;
    box-shadow: none; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #000;
    text-decoration: none;
    background-color: transparent; }
  .btn-link:disabled {
    color: #868e96; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1230px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 5px;
    height: 5px; }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-top .arrow, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #000; }
  .tooltip.bs-tooltip-right, .tooltip.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-right .arrow, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow {
      left: 0; }
    .tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
      margin-top: -3px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #000; }
  .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[x-placement^="bottom"] {
    padding: 5px 0; }
    .tooltip.bs-tooltip-bottom .arrow, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      margin-left: -3px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #000; }
  .tooltip.bs-tooltip-left, .tooltip.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 5px; }
    .tooltip.bs-tooltip-left .arrow, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow {
      right: 0; }
    .tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
      right: 0;
      margin-top: -3px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #000; }
  .tooltip .arrow::before {
    position: absolute;
    border-color: transparent;
    border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 5px; }
  .popover .arrow::before,
  .popover .arrow::after {
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow::before {
    content: "";
    border-width: 11px; }
  .popover .arrow::after {
    content: "";
    border-width: 11px; }
  .popover.bs-popover-top, .popover.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 10px; }
    .popover.bs-popover-top .arrow, .popover.bs-popover-auto[x-placement^="top"] .arrow {
      bottom: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before,
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      border-bottom-width: 0; }
    .popover.bs-popover-top .arrow::before, .popover.bs-popover-auto[x-placement^="top"] .arrow::before {
      bottom: -11px;
      margin-left: -6px;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-top .arrow::after, .popover.bs-popover-auto[x-placement^="top"] .arrow::after {
      bottom: -10px;
      margin-left: -6px;
      border-top-color: #fff; }
  .popover.bs-popover-right, .popover.bs-popover-auto[x-placement^="right"] {
    margin-left: 10px; }
    .popover.bs-popover-right .arrow, .popover.bs-popover-auto[x-placement^="right"] .arrow {
      left: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before,
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      margin-top: -8px;
      border-left-width: 0; }
    .popover.bs-popover-right .arrow::before, .popover.bs-popover-auto[x-placement^="right"] .arrow::before {
      left: -11px;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-right .arrow::after, .popover.bs-popover-auto[x-placement^="right"] .arrow::after {
      left: -10px;
      border-right-color: #fff; }
  .popover.bs-popover-bottom, .popover.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 10px; }
    .popover.bs-popover-bottom .arrow, .popover.bs-popover-auto[x-placement^="bottom"] .arrow {
      top: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      margin-left: -7px;
      border-top-width: 0; }
    .popover.bs-popover-bottom .arrow::before, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::before {
      top: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-bottom .arrow::after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow::after {
      top: -10px;
      border-bottom-color: #fff; }
    .popover.bs-popover-bottom .popover-header::before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.bs-popover-left, .popover.bs-popover-auto[x-placement^="left"] {
    margin-right: 10px; }
    .popover.bs-popover-left .arrow, .popover.bs-popover-auto[x-placement^="left"] .arrow {
      right: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before,
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      margin-top: -8px;
      border-right-width: 0; }
    .popover.bs-popover-left .arrow::before, .popover.bs-popover-auto[x-placement^="left"] .arrow::before {
      right: -11px;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .popover.bs-popover-left .arrow::after, .popover.bs-popover-auto[x-placement^="left"] .arrow::after {
      right: -10px;
      border-left-color: #fff; }

.popover-header {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 14px;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 9px 14px;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 576px) {
  .d-xs-none {
    display: none !important; }
  .d-xs-inline {
    display: inline !important; }
  .d-xs-inline-block {
    display: inline-block !important; }
  .d-xs-block {
    display: block !important; }
  .d-xs-table {
    display: table !important; }
  .d-xs-table-cell {
    display: table-cell !important; }
  .d-xs-flex {
    display: flex !important; }
  .d-xs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1230px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

@media (min-width: 576px) {
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1230px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 576px) {
  .float-xs-left {
    float: left !important; }
  .float-xs-right {
    float: right !important; }
  .float-xs-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1230px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

@media (min-width: 576px) {
  .m-xs-0 {
    margin: 0 !important; }
  .mt-xs-0 {
    margin-top: 0 !important; }
  .mr-xs-0 {
    margin-right: 0 !important; }
  .mb-xs-0 {
    margin-bottom: 0 !important; }
  .ml-xs-0 {
    margin-left: 0 !important; }
  .mx-xs-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xs-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xs-1 {
    margin: 0.25rem !important; }
  .mt-xs-1 {
    margin-top: 0.25rem !important; }
  .mr-xs-1 {
    margin-right: 0.25rem !important; }
  .mb-xs-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xs-1 {
    margin-left: 0.25rem !important; }
  .mx-xs-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xs-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xs-2 {
    margin: 0.5rem !important; }
  .mt-xs-2 {
    margin-top: 0.5rem !important; }
  .mr-xs-2 {
    margin-right: 0.5rem !important; }
  .mb-xs-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xs-2 {
    margin-left: 0.5rem !important; }
  .mx-xs-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xs-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xs-3 {
    margin: 1rem !important; }
  .mt-xs-3 {
    margin-top: 1rem !important; }
  .mr-xs-3 {
    margin-right: 1rem !important; }
  .mb-xs-3 {
    margin-bottom: 1rem !important; }
  .ml-xs-3 {
    margin-left: 1rem !important; }
  .mx-xs-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xs-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xs-4 {
    margin: 1.5rem !important; }
  .mt-xs-4 {
    margin-top: 1.5rem !important; }
  .mr-xs-4 {
    margin-right: 1.5rem !important; }
  .mb-xs-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xs-4 {
    margin-left: 1.5rem !important; }
  .mx-xs-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xs-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xs-5 {
    margin: 3rem !important; }
  .mt-xs-5 {
    margin-top: 3rem !important; }
  .mr-xs-5 {
    margin-right: 3rem !important; }
  .mb-xs-5 {
    margin-bottom: 3rem !important; }
  .ml-xs-5 {
    margin-left: 3rem !important; }
  .mx-xs-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xs-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-xs-0 {
    padding: 0 !important; }
  .pt-xs-0 {
    padding-top: 0 !important; }
  .pr-xs-0 {
    padding-right: 0 !important; }
  .pb-xs-0 {
    padding-bottom: 0 !important; }
  .pl-xs-0 {
    padding-left: 0 !important; }
  .px-xs-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xs-1 {
    padding: 0.25rem !important; }
  .pt-xs-1 {
    padding-top: 0.25rem !important; }
  .pr-xs-1 {
    padding-right: 0.25rem !important; }
  .pb-xs-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xs-1 {
    padding-left: 0.25rem !important; }
  .px-xs-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xs-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xs-2 {
    padding: 0.5rem !important; }
  .pt-xs-2 {
    padding-top: 0.5rem !important; }
  .pr-xs-2 {
    padding-right: 0.5rem !important; }
  .pb-xs-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xs-2 {
    padding-left: 0.5rem !important; }
  .px-xs-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xs-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xs-3 {
    padding: 1rem !important; }
  .pt-xs-3 {
    padding-top: 1rem !important; }
  .pr-xs-3 {
    padding-right: 1rem !important; }
  .pb-xs-3 {
    padding-bottom: 1rem !important; }
  .pl-xs-3 {
    padding-left: 1rem !important; }
  .px-xs-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xs-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xs-4 {
    padding: 1.5rem !important; }
  .pt-xs-4 {
    padding-top: 1.5rem !important; }
  .pr-xs-4 {
    padding-right: 1.5rem !important; }
  .pb-xs-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xs-4 {
    padding-left: 1.5rem !important; }
  .px-xs-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xs-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xs-5 {
    padding: 3rem !important; }
  .pt-xs-5 {
    padding-top: 3rem !important; }
  .pr-xs-5 {
    padding-right: 3rem !important; }
  .pb-xs-5 {
    padding-bottom: 3rem !important; }
  .pl-xs-5 {
    padding-left: 3rem !important; }
  .px-xs-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xs-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-xs-auto {
    margin: auto !important; }
  .mt-xs-auto {
    margin-top: auto !important; }
  .mr-xs-auto {
    margin-right: auto !important; }
  .mb-xs-auto {
    margin-bottom: auto !important; }
  .ml-xs-auto {
    margin-left: auto !important; }
  .mx-xs-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xs-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1230px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@media (min-width: 576px) {
  .text-xs-left {
    text-align: left !important; }
  .text-xs-right {
    text-align: right !important; }
  .text-xs-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1230px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.h-reset-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; }

html {
  height: 100%;
  font-size: 10px; }

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%; }
  @media (max-width: 991px) {
    body {
      font-size: 13px; } }

img {
  max-width: 100%;
  height: auto; }

p {
  margin-bottom: 0;
  letter-spacing: 0.47px; }

button {
  cursor: pointer;
  outline: none !important; }

h2 {
  font-size: 72px;
  margin-bottom: 0;
  line-height: 0.8;
  letter-spacing: 4.1px; }
  @media (max-width: 1229px) {
    h2 {
      font-size: 60px; } }
  @media (max-width: 991px) {
    h2 {
      font-size: 50px; } }
  @media (max-width: 767px) {
    h2 {
      font-size: 32px; } }

h3,
h4 {
  margin: 0; }

h3 {
  letter-spacing: 1.8px; }

h4 {
  font-size: 16px;
  letter-spacing: 1.3px; }
  @media (max-width: 767px) {
    h4 {
      font-size: 15px; } }

.h-list {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0; }

.font-blue {
  color: #144b93; }

.text-upp {
  text-transform: uppercase; }

.fs-130 {
  font-size: 130px; }
  @media (max-width: 1229px) {
    .fs-130 {
      font-size: 105px; } }
  @media (max-width: 991px) {
    .fs-130 {
      font-size: 75px; } }
  @media (max-width: 767px) {
    .fs-130 {
      font-size: 50px; } }

.fs-120 {
  font-size: 120px; }
  @media (max-width: 1229px) {
    .fs-120 {
      font-size: 95px; } }
  @media (max-width: 991px) {
    .fs-120 {
      font-size: 70px; } }
  @media (max-width: 767px) {
    .fs-120 {
      font-size: 46px; } }

.fs-110 {
  font-size: 110px; }
  @media (max-width: 1229px) {
    .fs-110 {
      font-size: 88px; } }
  @media (max-width: 991px) {
    .fs-110 {
      font-size: 62px; } }
  @media (max-width: 767px) {
    .fs-110 {
      font-size: 42px; } }

.fs-100 {
  font-size: 100px; }
  @media (max-width: 1229px) {
    .fs-100 {
      font-size: 82px; } }
  @media (max-width: 991px) {
    .fs-100 {
      font-size: 58px; } }
  @media (max-width: 767px) {
    .fs-100 {
      font-size: 38px; } }

.fs-90 {
  font-size: 90px; }
  @media (max-width: 1229px) {
    .fs-90 {
      font-size: 70px; } }
  @media (max-width: 991px) {
    .fs-90 {
      font-size: 55px; } }
  @media (max-width: 767px) {
    .fs-90 {
      font-size: 36px; } }

.fs-72 {
  font-size: 72px; }
  @media (max-width: 1229px) {
    .fs-72 {
      font-size: 60px; } }
  @media (max-width: 991px) {
    .fs-72 {
      font-size: 50px; } }
  @media (max-width: 767px) {
    .fs-72 {
      font-size: 32px; } }

.fs-70 {
  font-size: 70px; }
  @media (max-width: 1229px) {
    .fs-70 {
      font-size: 60px; } }
  @media (max-width: 991px) {
    .fs-70 {
      font-size: 50px; } }
  @media (max-width: 767px) {
    .fs-70 {
      font-size: 32px; } }

.fs-62 {
  font-size: 62px; }
  @media (max-width: 1229px) {
    .fs-62 {
      font-size: 53px; } }
  @media (max-width: 991px) {
    .fs-62 {
      font-size: 43px; } }
  @media (max-width: 767px) {
    .fs-62 {
      font-size: 25px; } }

.fs-60 {
  font-size: 60px; }
  @media (max-width: 1229px) {
    .fs-60 {
      font-size: 52px; } }
  @media (max-width: 991px) {
    .fs-60 {
      font-size: 42px; } }
  @media (max-width: 767px) {
    .fs-60 {
      font-size: 24px; } }

.fs-36 {
  font-size: 36px; }
  @media (max-width: 1229px) {
    .fs-36 {
      font-size: 28px; } }
  @media (max-width: 991px) {
    .fs-36 {
      font-size: 24px; } }
  @media (max-width: 767px) {
    .fs-36 {
      font-size: 20px; } }

.fs-31 {
  font-size: 31px; }
  @media (max-width: 1229px) {
    .fs-31 {
      font-size: 26px; } }
  @media (max-width: 991px) {
    .fs-31 {
      font-size: 22px; } }
  @media (max-width: 767px) {
    .fs-31 {
      font-size: 18px; } }

.fs-24 {
  font-size: 24px; }
  @media (max-width: 1229px) {
    .fs-24 {
      font-size: 20px; } }
  @media (max-width: 991px) {
    .fs-24 {
      font-size: 18px; } }
  @media (max-width: 767px) {
    .fs-24 {
      font-size: 16px; } }

.fs-18 {
  font-size: 18px; }
  @media (max-width: 1229px) {
    .fs-18 {
      font-size: 17px; } }
  @media (max-width: 991px) {
    .fs-18 {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .fs-18 {
      font-size: 14px; } }

.block-title {
  color: #144b93;
  letter-spacing: 2px; }

.font-sansita {
  font-family: 'Sansita'; }

.page-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
  transition: all 0.15s ease; }
  .page-preloader.load {
    opacity: 0;
    visibility: hidden; }

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px; }
  .loader span {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: load 2s ease infinite; }
  .loader :first-child {
    background: #144b93;
    animation-delay: -1.5s; }
  .loader :nth-child(2) {
    background: #939597;
    animation-delay: -1s; }
  .loader :nth-child(3) {
    background: #144b93;
    animation-delay: -0.5s; }
  .loader :last-child {
    background: #939597; }

@keyframes load {
  0% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); }
  100% {
    transform: translate(0); } }

@-o-keyframes load {
  0% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); }
  100% {
    transform: translate(0); } }

@-ms-keyframes load {
  0% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); }
  100% {
    transform: translate(0); } }

@-webkit-keyframes load {
  0% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); }
  100% {
    transform: translate(0); } }

@-moz-keyframes load {
  0% {
    transform: translate(0); }
  25% {
    transform: translate(160%); }
  50% {
    transform: translate(160%, 160%); }
  75% {
    transform: translate(0, 160%); }
  100% {
    transform: translate(0); } }

.h-object-fit {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: block; }
  .h-object-fit > img {
    position: absolute;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    height: auto;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    object-fit: cover;
    max-height: none;
    max-width: none;
    height: 100%; }
    .no-object-fit .h-object-fit > img,
    .no-objectfit .h-object-fit > img {
      display: none; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/MONTSERRAT-LIGHT.eot");
  src: url("../fonts/Montserrat/MONTSERRAT-LIGHT.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/MONTSERRAT-LIGHT.woff") format("woff"), url("../fonts/Montserrat/MONTSERRAT-LIGHT.ttf") format("truetype"), url("../fonts/Montserrat/MONTSERRAT-LIGHT.svg#fontname") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/MONTSERRAT-REGULAR.eot");
  src: url("../fonts/Montserrat/MONTSERRAT-REGULAR.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/MONTSERRAT-REGULAR.woff") format("woff"), url("../fonts/Montserrat/MONTSERRAT-REGULAR.ttf") format("truetype"), url("../fonts/Montserrat/MONTSERRAT-REGULAR.svg#fontname") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/MONTSERRAT-MEDIUM.eot");
  src: url("../fonts/Montserrat/MONTSERRAT-MEDIUM.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/MONTSERRAT-MEDIUM.woff") format("woff"), url("../fonts/Montserrat/MONTSERRAT-MEDIUM.ttf") format("truetype"), url("../fonts/Montserrat/MONTSERRAT-MEDIUM.svg#fontname") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/MONTSERRAT-SEMIBOLD.eot");
  src: url("../fonts/Montserrat/MONTSERRAT-SEMIBOLD.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/MONTSERRAT-SEMIBOLD.woff") format("woff"), url("../fonts/Montserrat/MONTSERRAT-SEMIBOLD.ttf") format("truetype"), url("../fonts/Montserrat/MONTSERRAT-SEMIBOLD.svg#fontname") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/MONTSERRAT-BOLD.eot");
  src: url("../fonts/Montserrat/MONTSERRAT-BOLD.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/MONTSERRAT-BOLD.woff") format("woff"), url("../fonts/Montserrat/MONTSERRAT-BOLD.ttf") format("truetype"), url("../fonts/Montserrat/MONTSERRAT-BOLD.svg#fontname") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Sansita";
  src: url("../fonts/Sanista/SANSITA-BOLDITALIC.eot");
  src: url("../fonts/Sanista/SANSITA-BOLDITALIC.eot?#iefix") format("embedded-opentype"), url("../fonts/Sanista/SANSITA-BOLDITALIC.woff") format("woff"), url("../fonts/Sanista/SANSITA-BOLDITALIC.ttf") format("truetype"), url("../fonts/Sanista/SANSITA-BOLDITALIC.svg#fontname") format("svg");
  font-weight: 700;
  font-style: italic; }

.l-wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden; }
  .l-wrapper_padding {
    padding-top: 111px; }
    @media (max-width: 1500px) {
      .l-wrapper_padding {
        padding-top: 110px; } }
    @media (max-width: 1380px) {
      .l-wrapper_padding {
        padding-top: 94px; } }
    @media (max-width: 991px) {
      .l-wrapper_padding {
        padding-top: 72px; } }
    @media (max-width: 575px) {
      .l-wrapper_padding {
        padding-top: 70px; } }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }
  .icon-facebook {
    width: 0.46663566612415713em; }
  .icon-google-plus {
    width: 1.303046357615894em; }
  .icon-youtube {
    width: 1.4207149404216317em; }
  .icon-twitter {
    width: 1.2311406155703077em; }
  .icon-service-1 {
    width: 0.984375em; }
  .icon-service-2 {
    width: 1.171875em; }
  .icon-service-3 {
    width: 1.0819672131147542em; }
  .icon-service-4 {
    width: 0.828125em; }
  .icon-service-5 {
    width: 0.6366459627329192em; }
  .icon-service-6 {
    width: 1.8205128205128205em; }
  .icon-service-7, .icon-service-8 {
    width: 0.7808764940239044em; }
  .icon-service-9 {
    width: 0.890625em; }
  .icon-service-10 {
    width: 1.2313461538461539em; }
  .icon-service-11 {
    width: 0.5915492957746479em; }
  .icon-service-12 {
    width: 0.741969696969697em; }
  .icon-service-13 {
    width: 1.0813438612788355em; }
  .icon-service-14 {
    width: 1.2378054781039856em; }
  .icon-service-15 {
    width: 1.0666666666666667em; }
  .icon-service-16 {
    width: 0.7808764940239044em; }
  .icon-service-17 {
    width: 0.96296875em; }
  .icon-service-18 {
    width: 0.9848484848484849em; }

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
	font-size: 32px;
	color: red;
}
========================================== */
button {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent; }

.btn {
  display: inline-block;
  height: 59px;
  border-radius: 30px;
  font-weight: 500;
  line-height: 59px;
  padding: 0 15px;
  font-size: 14px;
  letter-spacing: 2.5px;
  border: none;
  background-color: transparent;
  color: inherit;
  width: 100%;
  max-width: 230px;
  color: #fff;
  overflow: hidden;
  text-transform: uppercase;
  outline: none;
  transition: all 150ms ease; }
  @media (max-width: 991px) {
    .btn {
      font-size: 13px;
      height: 50px;
      line-height: 50px; } }
  .btn span {
    position: relative;
    z-index: 2;
    text-shadow: 0px 1px 1.96px rgba(0, 0, 0, 0.61); }
  .btn:hover {
    color: #fff; }
  .btn:focus, .btn:active {
    box-shadow: none; }
  .btn_blue {
    position: relative;
    background-image: -moz-linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);
    background-image: -webkit-linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);
    background-image: -ms-linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%);
    background-image: linear-gradient(to bottom, #1b75ca 0%, #1043a1 100%); }
    .btn_blue:after {
      content: '';
      position: absolute;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      border-radius: 30px;
      background-image: -moz-linear-gradient(to bottom, #2f89de 0%, #1a4dab 100%);
      background-image: -webkit-linear-gradient(to bottom, #2f89de 0%, #1a4dab 100%);
      background-image: -ms-linear-gradient(to bottom, #2f89de 0%, #1a4dab 100%);
      background-image: linear-gradient(to bottom, #2f89de 0%, #1a4dab 100%);
      transition: opacity 0.15s ease; }
    .btn_blue:hover:after {
      opacity: 1;
      visibility: visible; }
  .btn_sm {
    height: 44px;
    line-height: 44px;
    font-size: 12px;
    max-width: 200px; }
    @media (max-width: 991px) {
      .btn_sm {
        height: 36px;
        line-height: 36px; } }

.form-control {
  border: 1px solid gainsboro;
  background-color: #f1f1f1;
  height: 40px;
  padding: 5px 13px;
  box-shadow: none;
  border-radius: 0;
  color: #144b93;
  text-decoration: underline;
  font-weight: 500;
  line-height: 1.286;
  transition: border-color 0.15s ease, background-color 0.15s ease; }
  @media (max-width: 991px) {
    .form-control {
      height: 36px;
      font-size: 13px; } }
  .form-control::placeholder {
    transition: opacity 0.15s ease;
    opacity: 1; }
  .form-control:focus {
    box-shadow: none;
    border-color: #144b93;
    background-color: #f1f1f1;
    color: #144b93; }
    .form-control:focus::placeholder {
      opacity: 0; }
  .form-control.error {
    border-color: #ff0000 !important;
    background-color: #ffdede; }
  .form-control:disabled .form-control, .form-control[disabled] .form-control, .form-control.disabled .form-control {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.form-group {
  margin-bottom: 0; }
  .form-group:disabled .form-control, .form-group[disabled] .form-control, .form-group.disabled .form-control {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

textarea.form-control {
  height: 122px; }

.input-wrap,
.select-wrap {
  position: relative; }

.input-label,
.textarea-label {
  display: block; }

.input-ttl,
.textarea-ttl {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.28;
  letter-spacing: 1.1px;
  display: block;
  margin-bottom: 12px; }
  @media (max-width: 991px) {
    .input-ttl,
    .textarea-ttl {
      margin-bottom: 8px;
      font-size: 11px; } }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%; }

.bootstrap-select > .dropdown-toggle.dropdown-toggle, .bootstrap-select > .dropdown-toggle.dropdown-toggle:hover, .bootstrap-select > .dropdown-toggle.dropdown-toggle:focus, .bootstrap-select > .dropdown-toggle.dropdown-toggle:active, .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  outline: none !important; }

label {
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer; }
  label.error {
    color: #ff0000; }

.hidden-input {
  visibility: hidden !important;
  position: absolute !important;
  z-index: -1 !important;
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  left: 0 !important;
  top: 0 !important; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.field_clear_btn {
  transition: color 0.3s ease;
  margin-bottom: auto;
  position: absolute;
  margin-top: auto;
  display: block;
  color: #d8d8d8;
  height: 30px;
  width: 30px;
  bottom: 0;
  right: 0;
  top: 0; }
  .field_clear_btn:hover {
    border-color: #b61f27;
    color: #b61f27; }
  .field_clear_btn:before, .field_clear_btn:after {
    background-color: currentColor;
    position: absolute;
    display: block;
    margin: auto;
    content: '';
    height: 2px;
    width: 16px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  .field_clear_btn:before {
    transform: rotate(45deg); }
  .field_clear_btn:after {
    transform: rotate(-45deg); }

.checkbox-label {
  position: relative;
  padding-left: 30px; }
  .checkbox-label:disabled, .checkbox-label[disabled], .checkbox-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label .check-icon {
    border: solid 1px #979797;
    border-radius: 4px;
    position: absolute;
    display: block;
    height: 18px;
    width: 18px;
    left: 0;
    top: 2px; }
    .checkbox-label .check-icon:before {
      transform: rotate(-52.5deg) skew(-15deg);
      transition: opacity 0.3s ease;
      border-bottom: solid 3px #b61f27;
      border-left: solid 3px #b61f27;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 9px;
      width: 15px;
      opacity: 0;
      top: -10px;
      left: 5px;
      bottom: 0;
      right: 0; }
  .checkbox-label .check-value {
    transition: color 0.3s ease;
    color: #323232; }
  .checkbox-label.label_grey .check-value {
    color: #979797; }
  .checkbox-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .checkbox-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .checkbox-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.radio-label {
  padding-left: 20px;
  position: relative; }
  .radio-label:disabled, .radio-label[disabled], .radio-label.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label .check-icon {
    border: solid 1px #0171a6;
    border-radius: 50%;
    position: absolute;
    display: block;
    height: 13px;
    width: 13px;
    top: 4px;
    left: 0; }
    .radio-label .check-icon:before {
      transition: opacity 0.3s ease;
      background-color: #0171a6;
      border-radius: 50%;
      position: absolute;
      display: block;
      margin: auto;
      content: '';
      height: 5px;
      width: 5px;
      opacity: 0;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0; }
  .radio-label .check-value {
    transition: color 0.3s ease;
    color: #979797; }
  .radio-label.label_grey .check-value {
    color: #979797; }
  .radio-label input:checked ~ .check-icon:before {
    opacity: 1; }
  .radio-label input:disabled ~ .check-icon {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }
  .radio-label input:disabled ~ .check-value {
    pointer-events: none;
    cursor: default;
    opacity: 0.5; }

.footer {
  background-color: #626262;
  color: #fff;
  padding-top: 102px;
  padding-bottom: 54px; }
  @media (max-width: 1229px) {
    .footer {
      padding: 80px 0 45px; } }
  @media (max-width: 991px) {
    .footer {
      padding: 60px 0 40px; } }
  @media (max-width: 767px) {
    .footer {
      padding: 40px 0 30px; } }
  @media (max-width: 575px) {
    .footer {
      padding: 25px 0; } }
  .footer__wrap {
    padding-top: 57px;
    width: 985px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    @media (max-width: 1229px) {
      .footer__wrap {
        padding-top: 50px; } }
    @media (max-width: 991px) {
      .footer__wrap {
        padding-top: 40px; } }
    @media (max-width: 767px) {
      .footer__wrap {
        padding-top: 30px; } }
    @media (max-width: 575px) {
      .footer__wrap {
        padding-top: 25px; } }
  .footer__office {
    display: inline-block;
    margin-left: 36px; }
    @media (max-width: 1229px) {
      .footer__office {
        margin-left: 30px; } }
    @media (max-width: 991px) {
      .footer__office {
        margin-left: 20px; } }
    @media (max-width: 575px) {
      .footer__office {
        margin-left: 0;
        text-align: center;
        margin-top: 20px; } }
    .footer__office-item {
      display: inline-block;
      width: 220px;
      letter-spacing: 1.1px;
      vertical-align: top; }
      @media (max-width: 991px) {
        .footer__office-item {
          display: block;
          width: 260px; } }
      @media (max-width: 575px) {
        .footer__office-item {
          width: auto; } }
      .footer__office-item:nth-child(n + 2) {
        margin-left: 18px; }
        @media (max-width: 991px) {
          .footer__office-item:nth-child(n + 2) {
            margin-left: 0;
            margin-top: 20px; } }
      .footer__office-item-descr {
        font-weight: 300;
        margin-top: 20px;
        line-height: 1.76; }
        @media (max-width: 991px) {
          .footer__office-item-descr {
            margin-top: 10px; } }
  .footer__copyrights {
    font-size: 12px;
    letter-spacing: 0.2px;
    font-weight: 300;
    text-align: center;
    margin-top: 52px; }
    @media (max-width: 1229px) {
      .footer__copyrights {
        margin-top: 42px; } }
    @media (max-width: 991px) {
      .footer__copyrights {
        margin-top: 30px; } }
    @media (max-width: 767px) {
      .footer__copyrights {
        margin-top: 25px; } }
  .footer__nav {
    width: 462px;
    display: inline-block;
    vertical-align: top; }
    @media (max-width: 1229px) {
      .footer__nav {
        width: 422px; } }
    @media (max-width: 991px) {
      .footer__nav {
        width: auto; } }
    @media (max-width: 575px) {
      .footer__nav {
        text-align: center; } }
  @media (max-width: 991px) {
    .footer__main {
      display: flex;
      justify-content: space-between; } }
  @media (max-width: 575px) {
    .footer__main {
      flex-direction: column;
      align-items: center; } }
  @media (max-width: 991px) {
    .footer__list {
      display: flex;
      flex-direction: column; } }
  .footer__list li {
    display: inline-block;
    vertical-align: top;
    width: 220px; }
    @media (max-width: 1229px) {
      .footer__list li {
        width: 200px; } }
    @media (max-width: 991px) {
      .footer__list li {
        width: 100%; } }
    .footer__list li.active a {
      border-color: #bcc2ff;
      color: #bcc2ff;
      text-shadow: 1px 0 #bcc2ff; }
    .footer__list li:nth-child(2n) {
      margin-left: 18px; }
      @media (max-width: 991px) {
        .footer__list li:nth-child(2n) {
          margin-left: 0; } }
    .footer__list li:nth-child(n + 3) {
      margin-top: 21px; }
    @media (max-width: 991px) {
      .footer__list li:nth-child(n + 2) {
        margin-top: 15px; } }
    @media (max-width: 575px) {
      .footer__list li:nth-child(n + 2) {
        margin-top: 10px; } }
    .footer__list li a {
      display: inline-block;
      color: #fff;
      font-weight: 300;
      padding-left: 16px;
      letter-spacing: 1.2px;
      border-left: 3px solid transparent;
      transition: border-color 0.15s ease, color 0.15s ease, text-shadow 0.15s ease; }
      @media (max-width: 575px) {
        .footer__list li a {
          padding-left: 0;
          padding-bottom: 2px;
          border-left: none;
          border-bottom: 2px solid transparent; } }
      .footer__list li a:hover {
        border-color: #bcc2ff;
        color: #bcc2ff;
        text-shadow: 1px 0 #bcc2ff; }
  .footer_main {
    background-color: #fff; }
    .footer_main__copyrights {
      text-align: center;
      font-size: 12px;
      color: #acacac;
      font-weight: 300;
      padding-bottom: 45px;
      letter-spacing: 0.2px; }
      @media (max-width: 575px) {
        .footer_main__copyrights {
          padding: 5px 0 30px; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  max-height: 111px;
  overflow: hidden; }
  @media (max-width: 1500px) {
    .header {
      max-height: 110px; } }
  @media (max-width: 1380px) {
    .header {
      position: static; } }
  .header__main {
    display: flex;
    justify-content: space-between;
    max-width: 1772px;
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 1760px) {
      .header__main {
        max-width: 1500px; } }
    @media (max-width: 1500px) {
      .header__main {
        max-width: 1380px; } }
    @media (max-width: 1380px) {
      .header__main {
        position: fixed;
        max-width: none;
        margin: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 320px;
        background-color: #fff;
        box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.3);
        overflow: hidden;
        overflow-y: auto;
        z-index: 101;
        justify-content: flex-start;
        flex-direction: column;
        padding: 38px 0 30px 20px;
        transform: translateX(-350px);
        transition: transform 0.15s ease; }
        .header__main .header__logo,
        .header__main .header__call {
          display: none; } }
    .header__main-close {
      position: absolute;
      top: 5px;
      right: 10px;
      width: 28px;
      height: 24px;
      display: none; }
      @media (max-width: 1380px) {
        .header__main-close {
          display: block; } }
      .header__main-close:hover span:before, .header__main-close:hover span:after {
        background-color: #144b93; }
      .header__main-close span {
        position: relative;
        display: block;
        height: 4px;
        width: 30px;
        background-color: #fff; }
        .header__main-close span:before, .header__main-close span:after {
          content: '';
          position: absolute;
          display: block;
          width: 30px;
          height: 4px;
          background-color: #1f1f1f;
          left: 0;
          transition: background-color 0.15s ease; }
        .header__main-close span:before {
          top: 0;
          transform: rotateZ(45deg); }
        .header__main-close span:after {
          bottom: 0;
          transform: rotateZ(-45deg); }
  .header__lt, .header__rt {
    max-width: 757px; }
  .header__rt {
    display: flex;
    align-items: center; }
    @media (max-width: 1380px) {
      .header__rt {
        display: block;
        margin-top: 15px; } }
    .header__rt .header__call {
      margin-left: 104px; }
      @media (max-width: 1760px) {
        .header__rt .header__call {
          margin-left: 60px; } }
  .header__list {
    font-size: 14px; }
    @media (max-width: 1500px) {
      .header__list {
        font-size: 13px; } }
    .header__list li {
      display: inline-block; }
      @media (max-width: 1380px) {
        .header__list li {
          display: block; } }
      .header__list li:hover a, .header__list li.active a {
        border-color: #144b93;
        color: #144b93;
        text-shadow: 1px 0 #144b93; }
      .header__list li:nth-child(n + 2) {
        margin-left: 22px; }
        @media (max-width: 1760px) {
          .header__list li:nth-child(n + 2) {
            margin-left: 8px; } }
        @media (max-width: 1380px) {
          .header__list li:nth-child(n + 2) {
            margin-left: 0;
            margin-top: 15px; } }
      .header__list li a {
        display: block;
        padding-top: 41px;
        padding-bottom: 50px;
        color: #1f1f1f;
        border-top: 4px solid transparent;
        letter-spacing: 1.1px;
        transition: border-color 0.15s ease, color 0.15s ease, text-shadow 0.15s ease; }
        @media (max-width: 1380px) {
          .header__list li a {
            padding: 5px 15px 5px 0;
            border-top: none;
            border-right: 4px solid transparent; } }
  .header__logo {
    position: absolute;
    top: 9px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20; }
    @media (max-width: 1380px) {
      .header__logo {
        top: 0; } }
    @media (max-width: 991px) {
      .header__logo {
        max-width: 170px; } }
    @media (max-width: 575px) {
      .header__logo {
        display: none; } }
    .header__logo_xx {
      display: none; }
      @media (max-width: 575px) {
        .header__logo_xx {
          display: block; } }
  .header__call {
    display: inline-block;
    font-size: 26px;
    font-family: 'Roboto';
    font-weight: bold;
    letter-spacing: 2px; }
    @media (max-width: 1500px) {
      .header__call {
        font-size: 20px;
        padding-bottom: 6px; } }
    @media (max-width: 767px) {
      .header__call {
        font-size: 26px; } }
    .header__call:hover a {
      color: #144b93;
      transition: color 0.15s ease; }
    .header__call a svg {
      display: none; }
      @media (max-width: 767px) {
        .header__call a svg {
          display: block; } }
    @media (max-width: 767px) {
      .header__call a span {
        display: none; } }
  .header__phone {
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 94px; }
    @media (max-width: 1380px) {
      .header__phone {
        display: flex;
        padding: 0 15px;
        z-index: 49;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: #fff; } }
    @media (max-width: 991px) {
      .header__phone {
        height: 72px; } }
    @media (max-width: 575px) {
      .header__phone {
        height: 70px; } }
    .header__phone-menu-open {
      width: 40px;
      height: 18px; }
      .header__phone-menu-open:hover span {
        background-color: #144b93; }
        .header__phone-menu-open:hover span:before, .header__phone-menu-open:hover span:after {
          background-color: #144b93; }
        .header__phone-menu-open:hover span:before {
          top: -9px; }
        .header__phone-menu-open:hover span:after {
          bottom: -9px; }
      .header__phone-menu-open span {
        position: relative;
        display: block;
        height: 4px;
        width: 100%;
        background-color: #1f1f1f;
        transition: background-color 0.15s ease; }
        .header__phone-menu-open span:before, .header__phone-menu-open span:after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 4px;
          background-color: #1f1f1f;
          left: 0;
          transition: top 0.15s ease, bottom 0.15s ease, background-color 0.15s ease; }
        .header__phone-menu-open span:before {
          top: -7px; }
        .header__phone-menu-open span:after {
          bottom: -7px; }

.menu-open {
  overflow: hidden;
  height: 100vh; }
  .menu-open .header__main {
    transform: translateX(0); }
  .menu-open .close-menu-field {
    opacity: 1;
    visibility: visible; }

.close-menu-field {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  background-color: rgba(18, 20, 21, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease; }

.section-banner {
  position: relative;
  background: transparent;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat; }
  .section-banner_main {
    padding: 273px 0 426px;
    position: relative;
    background-attachment: fixed; }
    .section-banner_main:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #000;
      opacity: 0.08; }
    @media (max-width: 1380px) {
      .section-banner_main {
        padding: 205px 0 224px; } }
    @media (max-width: 1229px) {
      .section-banner_main {
        background-size: 140%; } }
    @media (max-width: 991px) {
      .section-banner_main {
        padding: 150px 0 180px; } }
    @media (max-width: 767px) {
      .section-banner_main {
        padding: 100px 0 150px; } }
    @media (max-width: 575px) {
      .section-banner_main {
        padding: 60px 0 100px;
        background-size: 160%; } }
    .section-banner_main .banner__title span:first-child {
      letter-spacing: 5.3px; }
      @media (max-width: 767px) {
        .section-banner_main .banner__title span:first-child {
          letter-spacing: 3px; } }
    .section-banner_main .banner__title span:nth-child(2) {
      margin-top: 12px; }
      @media (max-width: 767px) {
        .section-banner_main .banner__title span:nth-child(2) {
          margin-top: 4px; } }
    .section-banner_main .banner__title span:last-child {
      line-height: 0.73; }
  .section-banner_pages {
    background-position: center;
    padding: 114px 0 184px; }
    @media (max-width: 1229px) {
      .section-banner_pages {
        padding: 100px 0 150px; } }
    @media (max-width: 991px) {
      .section-banner_pages {
        padding: 85px 0 130px; } }
    @media (max-width: 767px) {
      .section-banner_pages {
        padding: 70px 0 120px; } }
    @media (max-width: 575px) {
      .section-banner_pages {
        padding: 60px 0 100px; } }
    .section-banner_pages:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #858584;
      opacity: 0.25; }
    @media (min-width: 1230px) {
      .section-banner_pages-service {
        padding: 178px 0 193px; } }
    @media (min-width: 1230px) {
      .section-banner_pages-pricing {
        padding: 169px 0 201px; } }
    .section-banner_pages-insurance {
      background-position-y: 29%; }
      @media (min-width: 1230px) {
        .section-banner_pages-insurance {
          padding: 168px 0 202px; } }
    @media (min-width: 1230px) {
      .section-banner_pages-contacts {
        padding: 178px 0 192px; } }
    .section-banner_pages-testimonials {
      background-position-y: 35%; }

.banner__title {
  position: relative;
  margin: 0 auto;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 1; }
  .banner__title .font-sansita {
    letter-spacing: 7.3px;
    line-height: 1; }
    @media (max-width: 767px) {
      .banner__title .font-sansita {
        letter-spacing: 4px; } }

.banner__scroll {
  position: absolute;
  bottom: 112px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  cursor: pointer; }
  @media (max-width: 1380px) {
    .banner__scroll {
      bottom: 80px; } }
  @media (max-width: 1229px) {
    .banner__scroll {
      bottom: 60px; } }
  @media (max-width: 767px) {
    .banner__scroll {
      display: none; } }
  .banner__scroll-name {
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px; }
    @media (max-width: 1380px) {
      .banner__scroll-name {
        font-size: 14px; } }
  .banner__scroll-line {
    display: block;
    background-color: #fff;
    width: 1px;
    height: 80px;
    margin-top: 14px; }
    @media (max-width: 1380px) {
      .banner__scroll-line {
        height: 50px;
        margin-top: 10px; } }
    @media (max-width: 991px) {
      .banner__scroll-line {
        display: none; } }
  .banner__scroll-mouse {
    position: relative;
    border: 1px solid #fff;
    width: 17px;
    height: 30px;
    border-radius: 8px;
    margin-top: 20px; }
    @media (max-width: 1380px) {
      .banner__scroll-mouse {
        margin-top: 15px; } }
    .banner__scroll-mouse:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      right: 0;
      margin: auto;
      height: 6px;
      width: 3px;
      border-radius: 2px;
      background-color: #fff; }

.section-after {
  position: relative; }
  .section-after:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-color: transparent transparent #fff transparent;
    border-style: solid;
    border-width: 0 0 62px 100vw; }
    @media (max-width: 1229px) {
      .section-after:after {
        border-width: 0 0 50px 100vw; } }
    @media (max-width: 991px) {
      .section-after:after {
        border-width: 0 0 40px 100vw; } }
    @media (max-width: 767px) {
      .section-after:after {
        border-width: 0 0 35px 100vw; } }
    @media (max-width: 575px) {
      .section-after:after {
        border-width: 0 0 25px 100vw; } }
  .section-after_main:after {
    border-width: 0 0 122px 100vw; }
    @media (max-width: 1229px) {
      .section-after_main:after {
        border-width: 0 0 90px 100vw; } }
    @media (max-width: 991px) {
      .section-after_main:after {
        border-width: 0 0 70px 100vw; } }
    @media (max-width: 767px) {
      .section-after_main:after {
        border-width: 0 0 55px 100vw; } }
    @media (max-width: 575px) {
      .section-after_main:after {
        border-width: 0 0 30px 100vw; } }

.section-before {
  position: relative; }
  .section-before:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 102px 100vw 0 0; }
    @media (max-width: 1229px) {
      .section-before:before {
        border-width: 80px 100vw 0 0; } }
    @media (max-width: 991px) {
      .section-before:before {
        border-width: 60px 100vw 0 0; } }
    @media (max-width: 767px) {
      .section-before:before {
        border-width: 40px 100vw 0 0; } }
    @media (max-width: 575px) {
      .section-before:before {
        border-width: 25px 100vw 0 0; } }

.section-socials {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 50vh;
  transform: translateY(-50%);
  box-shadow: 0px 13px 13.8px 1.2px rgba(0, 0, 0, 0.1); }

.socials__item {
  width: 42px;
  height: 42px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  color: #c1bfbe;
  font-size: 16px; }
  @media (max-width: 991px) {
    .socials__item {
      width: 34px;
      height: 34px;
      font-size: 13px; } }
  .socials__item:hover {
    color: #858584;
    transition: color 0.15s ease; }
  .socials__item:nth-child(n + 2) {
    border-top: 1px solid #e5e5e4; }
  .socials__item .icon-facebook, .socials__item .icon-instagram {
    font-size: 1.125em; }
  .socials__item .icon-youtube, .socials__item .icon-twitter {
    font-size: 0.875em; }

.section-mission {
  padding: 49px 0 192px; }
  @media (max-width: 1229px) {
    .section-mission {
      padding: 40px 0 120px; } }
  @media (max-width: 991px) {
    .section-mission {
      padding: 30px 0 110px; } }
  @media (max-width: 767px) {
    .section-mission {
      padding: 25px 0 30px; } }

.mission__wrap {
  position: relative; }

.mission__title {
  text-align: center;
  max-width: 600px;
  margin: 0 auto; }
  @media (max-width: 991px) {
    .mission__title {
      max-width: 400px; } }
  .mission__title h2 {
    color: #144b93; }

.mission__descr {
  display: flex;
  justify-content: space-between;
  margin-top: 47px; }
  @media (max-width: 1229px) {
    .mission__descr {
      margin-top: 37px; } }
  @media (max-width: 767px) {
    .mission__descr {
      flex-direction: column;
      margin-top: 20px; } }
  .mission__descr p:nth-child(n + 2) {
    margin-top: 16px; }
    @media (max-width: 767px) {
      .mission__descr p:nth-child(n + 2) {
        margin-top: 12px; } }
  .mission__descr-lt, .mission__descr-rt {
    width: 48.7%; }
    @media (max-width: 767px) {
      .mission__descr-lt, .mission__descr-rt {
        width: 100%; } }
  @media (max-width: 767px) {
    .mission__descr-rt {
      margin-top: 16px; } }
  @media (max-width: 767px) {
    .mission__descr-rt {
      margin-top: 12px; } }

.mission__message {
  position: relative;
  font-size: 34px;
  color: #144b93;
  line-height: 1.412;
  text-align: center;
  letter-spacing: 2.4px;
  margin: 27px auto 0;
  max-width: 750px; }
  @media (max-width: 1229px) {
    .mission__message {
      font-size: 30px; } }
  @media (max-width: 991px) {
    .mission__message {
      font-size: 26px;
      margin-top: 20px; } }
  @media (max-width: 767px) {
    .mission__message {
      font-size: 22px; } }
  @media (max-width: 575px) {
    .mission__message {
      font-size: 18px;
      margin-top: 16px; } }
  .mission__message-img {
    position: absolute;
    top: -38px;
    left: -54px;
    z-index: 3;
    pointer-events: none; }
    @media (max-width: 1229px) {
      .mission__message-img {
        transform: scale(0.6);
        top: -36px;
        left: -4px; } }
    @media (max-width: 991px) {
      .mission__message-img {
        display: none; } }

.mission__decoration-img {
  position: absolute;
  z-index: 3;
  pointer-events: none; }
  @media (max-width: 1229px) {
    .mission__decoration-img {
      transform: scale(0.6); } }
  @media (max-width: 1229px) {
    .mission__decoration-img.js-parallax-foreground img {
      transform: scale(0.6); } }
  .mission__decoration-img-1 {
    top: -111px;
    left: 15px; }
    @media (max-width: 1229px) {
      .mission__decoration-img-1 {
        top: -91px;
        left: 10px; } }
    @media (max-width: 767px) {
      .mission__decoration-img-1 {
        top: -80px;
        left: 0; } }
    @media (max-width: 575px) {
      .mission__decoration-img-1 {
        display: none; } }
  .mission__decoration-img-2 {
    right: 120px;
    top: -30px; }
    @media (max-width: 1229px) {
      .mission__decoration-img-2 {
        right: 30px;
        top: -38px; } }
    @media (max-width: 991px) {
      .mission__decoration-img-2 {
        right: 0; } }
    @media (max-width: 767px) {
      .mission__decoration-img-2 {
        display: none; } }
  .mission__decoration-img-3 {
    bottom: -191px;
    left: -196px; }
    @media (max-width: 1229px) {
      .mission__decoration-img-3 {
        bottom: -152px;
        left: -176px; } }
    @media (max-width: 767px) {
      .mission__decoration-img-3 {
        display: none; } }
  .mission__decoration-img-4 {
    right: -174px;
    bottom: -148px; }
    @media (max-width: 1229px) {
      .mission__decoration-img-4 {
        right: -124px;
        bottom: -118px; } }
    @media (max-width: 991px) {
      .mission__decoration-img-4 {
        right: -123px;
        bottom: -147px; } }
    @media (max-width: 767px) {
      .mission__decoration-img-4 {
        display: none; } }

.section-inside {
  padding: 200px 0 155px;
  background: url(../images/useful/home/inside-bg.jpg) no-repeat center;
  background-size: cover;
  background-position: 50% 0;
  position: relative;
  background-attachment: fixed; }
  @media (max-width: 1229px) {
    .section-inside {
      padding: 180px 0 130px; } }
  @media (max-width: 991px) {
    .section-inside {
      padding: 140px 0 100px; } }
  @media (max-width: 767px) {
    .section-inside {
      padding: 100px 0 80px; } }
  @media (max-width: 575px) {
    .section-inside {
      padding: 80px 0 60px; } }

.inside__title {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff; }
  .inside__title span:first-child {
    letter-spacing: 2.8px; }
  .inside__title span:last-child {
    line-height: 1;
    margin-top: 4px;
    letter-spacing: 5px; }

.inside__link {
  margin-top: 39px;
  text-align: center; }
  @media (max-width: 991px) {
    .inside__link {
      margin-top: 25px; } }
  @media (max-width: 767px) {
    .inside__link {
      margin-top: 18px; } }

.section-services {
  padding-top: 99px; }
  @media (max-width: 1229px) {
    .section-services {
      padding-top: 70px; } }
  @media (max-width: 991px) {
    .section-services {
      padding-top: 50px; } }
  @media (max-width: 767px) {
    .section-services {
      padding-top: 40px; } }

.services__title {
  text-align: center; }
  .services__title h2 {
    position: relative;
    display: inline-block; }
  .services__title-decoration-img-1 {
    left: -186px;
    top: -124px; }
    @media (max-width: 1229px) {
      .services__title-decoration-img-1 {
        left: -166px; } }
    @media (max-width: 767px) {
      .services__title-decoration-img-1 {
        display: none; } }

.services__items {
  position: relative;
  margin-top: 76px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (max-width: 1229px) {
    .services__items {
      margin-top: 60px; } }
  @media (max-width: 991px) {
    .services__items {
      margin-top: 40px; } }
  @media (max-width: 767px) {
    .services__items {
      margin-top: 25px; } }
  @media (max-width: 1380px) {
    .services__items-decoration .mission__decoration-img img {
      transform: scale(0.65); } }
  .services__items-decoration-img-1 {
    top: -14px;
    right: 195px; }
    @media (max-width: 1229px) {
      .services__items-decoration-img-1 {
        top: -58px;
        right: 165px; } }
    @media (max-width: 767px) {
      .services__items-decoration-img-1 {
        display: none; } }
  .services__items-decoration-img-2 {
    bottom: 265px;
    left: 241px; }
    @media (max-width: 1229px) {
      .services__items-decoration-img-2 {
        bottom: 239px;
        left: 191px; } }
    @media (max-width: 767px) {
      .services__items-decoration-img-2 {
        display: none; } }

.services__item {
  position: relative;
  height: 350px;
  width: 16.53%;
  display: flex;
  justify-content: center;
  padding: 105px 15px 5px;
  margin: 0 0.164% 2px 0;
  overflow: hidden; }
  @media (max-width: 1500px) {
    .services__item {
      height: 320px;
      padding-top: 90px; } }
  @media (max-width: 1380px) {
    .services__item {
      height: 270px;
      padding-top: 60px; } }
  @media (max-width: 1229px) {
    .services__item {
      width: 33.2%; } }
  @media (max-width: 991px) {
    .services__item {
      padding: 80px 10px 5px; } }
  @media (max-width: 767px) {
    .services__item {
      width: 49.8%; } }
  @media (max-width: 575px) {
    .services__item {
      width: 100%;
      margin-right: 0;
      height: 220px;
      padding-top: 50px; } }
  .services__item:nth-child(6n) {
    margin-right: 0; }
  @media (max-width: 1229px) {
    .services__item:nth-child(3n) {
      margin-right: 0; } }
  @media (max-width: 767px) {
    .services__item:nth-child(3n) {
      margin-right: 0.164%; } }
  @media (max-width: 767px) {
    .services__item:nth-child(2n) {
      margin-right: 0; } }
  .services__item:hover:after {
    opacity: 0.51; }
  .services__item:hover:before {
    opacity: 0; }
  .services__item:hover .services__item-img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0); }
    .services__item:hover .services__item-img img {
      filter: blur(0); }
  .services__item:hover .services__item-info {
    color: #fff;
    transform: translateY(93px); }
    @media (max-width: 1500px) {
      .services__item:hover .services__item-info {
        transform: translateY(60px); } }
    @media (max-width: 575px) {
      .services__item:hover .services__item-info {
        transform: translateY(30px); } }
  .services__item-img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    filter: grayscale(30%);
    -webkit-filter: grayscale(30%);
    -moz-filter: grayscale(30%);
    -ms-filter: grayscale(30%);
    -o-filter: grayscale(30%);
    transition: filter 0.15s ease; }
    .services__item-img img {
      filter: blur(4.4px); }
  .services__item:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0.7;
    z-index: 1;
    transition: opacity 0.15s ease; }
  .services__item:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-image: linear-gradient(to top, #4056f8 0%, transparent 80%);
    background-image: -moz-linear-gradient(to top, #4056f8 0%, transparent 80%);
    background-image: -webkit-linear-gradient(to top, #4056f8 0%, transparent 80%);
    background-image: -ms-linear-gradient(to top, #4056f8 0%, transparent 80%);
    opacity: 0;
    transition: opacity 0.15s ease; }
  .services__item-info {
    position: relative;
    color: #144b93;
    line-height: 1.125;
    text-align: center;
    max-width: 190px;
    z-index: 2;
    transform: translateY(0);
    transition: color 0.15s ease, transform 0.15s ease; }
  .services__item-icon {
    display: flex;
    justify-content: center;
    font-size: 62px;
    height: 75px;
    overflow: hidden; }
    @media (max-width: 991px) {
      .services__item-icon {
        font-size: 54px;
        height: 62px; } }
  .services__item-descr {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 12px; }
    @media (max-width: 991px) {
      .services__item-descr {
        font-size: 15px;
        margin-top: 8px; } }

.section-slider {
  overflow: hidden; }

@media (max-width: 767px) {
  .slider__item {
    position: relative;
    height: 300px; }
    .slider__item-img {
      width: 100%;
      height: 100%; }
    .slider__item-cont {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding: 35px 0; }
      .slider__item-cont-ttl {
        position: relative; }
        .slider__item-cont-ttl span {
          display: block; }
      .slider__item-cont-link {
        margin-top: 20px; } }

@media (max-width: 575px) {
  .slider__item {
    height: 250px; } }

@media (min-width: 768px) {
  .slider__item {
    transform: skewY(-4.5deg);
    position: relative;
    overflow: hidden;
    width: 100%; }
    .slider__item:before {
      visibility: hidden;
      display: block;
      content: ''; }
    .slider__item-top {
      margin-top: -5.5%; }
      .slider__item-top:before {
        padding-bottom: 38.3%; }
      .slider__item-top .slider__item-img {
        bottom: -62%;
        top: 5%; }
      .slider__item-top .slider__item-cont {
        top: 9vw; }
      .slider__item-top .slider__item-cont-link {
        margin-top: 12.3vw; } }
      @media (min-width: 768px) and (max-width: 1380px) {
        .slider__item-top .slider__item-cont-link {
          margin-top: 8vw; } }

@media (min-width: 768px) {
    .slider__item-bot {
      margin-bottom: -5%; }
      .slider__item-bot:before {
        padding-bottom: 30.2%; }
      .slider__item-bot .slider__item-img {
        bottom: -44%;
        top: -15%; }
      .slider__item-bot .slider__item-cont {
        top: 6.8vw;
        max-height: 182px;
        overflow: hidden; } }
      @media (min-width: 768px) and (max-width: 1229px) {
        .slider__item-bot .slider__item-cont {
          max-height: 150px; } }
      @media (min-width: 768px) and (max-width: 991px) {
        .slider__item-bot .slider__item-cont {
          max-height: 110px; } }

@media (min-width: 768px) {
      .slider__item-bot .slider__item-cont-ttl span:first-child {
        letter-spacing: 2.7px;
        line-height: 1; }
      .slider__item-bot .slider__item-cont-ttl span:last-child {
        line-height: 1; }
    .slider__item-img {
      transform: skewY(4.5deg);
      position: absolute;
      right: 0;
      left: 0; }
    .slider__item-cont {
      transform: skewY(4.5deg);
      position: absolute;
      display: block;
      width: 100%;
      text-align: center;
      color: #fff; }
      .slider__item-cont-ttl .font-sansita {
        letter-spacing: 10px; }
      .slider__item-cont-ttl span {
        display: block; }
        .slider__item-cont-ttl span.img-wrap {
          display: inline-block;
          position: relative; } }

.slider__decoration-img-1 {
  right: -41px;
  top: -25px; }
  @media (max-width: 767px) {
    .slider__decoration-img-1 {
      display: none; } }

.slick-dots {
  position: absolute;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  bottom: 20px; }
  @media (min-width: 768px) {
    .slick-dots {
      bottom: 2.7vw; } }
  .slick-dots li {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: color 0.15s ease, border-color 0.15s ease; }
    @media (max-width: 991px) {
      .slick-dots li {
        width: 10px;
        height: 10px; } }
    .slick-dots li:nth-child(n + 2) {
      margin-left: 13px; }
      @media (max-width: 991px) {
        .slick-dots li:nth-child(n + 2) {
          margin-left: 10px; } }
    .slick-dots li.slick-active {
      background-color: #144b93;
      border-color: #144b93; }
    .slick-dots li button {
      display: none; }

.slider-img_parallax {
  position: relative;
  background: transparent;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; }

.section-office {
  display: flex;
  margin-top: 5px; }
  @media (max-width: 991px) {
    .section-office {
      flex-direction: column; } }

.office__lt {
  width: 54.3%; }
  @media (max-width: 991px) {
    .office__lt {
      height: 300px;
      width: 100%; } }
  @media (max-width: 575px) {
    .office__lt {
      height: 250px; } }

.office__rt {
  position: relative;
  width: 45.7%; }
  @media (max-width: 991px) {
    .office__rt {
      width: 100%; } }

.office__link {
  text-align: center;
  position: absolute;
  bottom: 136px;
  left: 0;
  right: 0;
  z-index: 3; }
  @media (max-width: 991px) {
    .office__link {
      bottom: auto;
      top: 275px; } }
  @media (max-width: 575px) {
    .office__link {
      top: 225px; } }

.office__map {
  height: 700px; }
  @media (max-width: 1229px) {
    .office__map {
      height: 550px; } }
  @media (max-width: 991px) {
    .office__map {
      height: 400px; } }
  @media (max-width: 575px) {
    .office__map {
      height: 250px; } }
  .office__map-gradient {
    position: relative; }
    .office__map-gradient:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: -moz-linear-gradient(to right, #fff 0%, transparent 100%);
      background-image: -webkit-linear-gradient(to right, #fff 0%, transparent 100%);
      background-image: -ms-linear-gradient(to right, #fff 0%, transparent 100%);
      background-image: linear-gradient(to right, #fff 0%, transparent 100%);
      opacity: 0.3;
      z-index: 1;
      pointer-events: none; }
  .office__map-descr {
    position: absolute;
    top: 100px;
    left: 11.8vw;
    z-index: 1;
    pointer-events: none; }
    @media (max-width: 1229px) {
      .office__map-descr {
        top: 60px; } }
    @media (max-width: 575px) {
      .office__map-descr {
        top: 30px; } }
  .office__map-ttl {
    letter-spacing: 4.3px; }
  .office__map-address {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    margin-top: 66px;
    letter-spacing: 1.3px; }
    @media (max-width: 1229px) {
      .office__map-address {
        margin-top: 50px; } }
    @media (max-width: 991px) {
      .office__map-address {
        font-size: 15px;
        margin-top: 40px; } }
    @media (max-width: 767px) {
      .office__map-address {
        font-size: 14px; } }
    @media (max-width: 575px) {
      .office__map-address {
        font-size: 13px;
        margin-top: 20px; } }

.map {
  height: 100%;
  width: 100%; }

.section-services-page {
  padding: 100px 0 87px; }
  @media (max-width: 1229px) {
    .section-services-page {
      padding: 80px 0 70px; } }
  @media (max-width: 991px) {
    .section-services-page {
      padding: 60px 0 50px; } }
  @media (max-width: 767px) {
    .section-services-page {
      padding: 40px 0 30px; } }
  @media (max-width: 575px) {
    .section-services-page {
      padding: 30px 0; } }

.services-page__wrap {
  width: 980px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }

.services-page__decoration-img-1 {
  left: -281px;
  top: 180px; }
  @media (max-width: 1229px) {
    .services-page__decoration-img-1 {
      left: -200px; } }
  @media (max-width: 767px) {
    .services-page__decoration-img-1 {
      display: none; } }

.services-page__decoration-img-2 {
  right: -266px;
  top: 44.8%; }
  @media (max-width: 1229px) {
    .services-page__decoration-img-2 {
      right: -206px; } }
  @media (max-width: 767px) {
    .services-page__decoration-img-2 {
      display: none; } }

.services-page__decoration-img-3 {
  bottom: -56px;
  left: -248px; }
  @media (max-width: 1229px) {
    .services-page__decoration-img-3 {
      left: -188px; } }
  @media (max-width: 767px) {
    .services-page__decoration-img-3 {
      display: none; } }

.services-page__cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.services-page__card {
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 185px;
  background-color: #f1f1f1;
  width: 480px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  padding: 21px 20px 25px 53px; }
  @media (max-width: 1229px) {
    .services-page__card {
      width: 455px;
      padding-left: 40px; } }
  @media (max-width: 991px) {
    .services-page__card {
      width: 335px;
      padding: 15px 15px 18px 20px;
      min-height: 160px; } }
  @media (max-width: 767px) {
    .services-page__card {
      padding: 15px;
      width: 245px; } }
  @media (max-width: 575px) {
    .services-page__card {
      width: 100%;
      min-height: none; } }
  .services-page__card:nth-child(n + 3) {
    margin-top: 19px; }
    @media (max-width: 767px) {
      .services-page__card:nth-child(n + 3) {
        margin-top: 15px; } }
  @media (max-width: 575px) {
    .services-page__card:nth-child(n + 2) {
      margin-top: 15px; } }
  .services-page__card-wrap {
    display: flex;
    align-items: center;
    position: relative; }
    @media (max-width: 767px) {
      .services-page__card-wrap {
        flex-direction: column; } }
  .services-page__card:hover:before {
    width: 100%; }
  .services-page__card:hover .services-page__card-icon {
    transform: scale(1.032) translateZ(0); }
  .services-page__card:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    content: '';
    transition: width 0.35s ease; }
  .services-page__card-about {
    margin-left: 34px; }
    @media (max-width: 991px) {
      .services-page__card-about {
        margin-left: 20px; } }
    @media (max-width: 767px) {
      .services-page__card-about {
        margin-left: 0;
        margin-top: 15px; } }
  .services-page__card-descr {
    margin-top: 8px;
    max-height: 48px;
    overflow: hidden; }
    @media (max-width: 991px) {
      .services-page__card-descr {
        margin-top: 5px;
        max-height: 45px; } }
    @media (max-width: 575px) {
      .services-page__card-descr {
        max-height: 30px; } }
  .services-page__card-link {
    margin-top: 20px; }
    @media (max-width: 991px) {
      .services-page__card-link {
        margin-top: 15px; } }
    @media (max-width: 767px) {
      .services-page__card-link {
        margin-top: 10px; } }
  .services-page__card-icon {
    height: auto;
    color: #1c5197;
    flex-shrink: 0;
    width: 72px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: translateZ(0);
    transition: transform 0.75s ease; }
    @media (max-width: 991px) {
      .services-page__card-icon {
        width: 55px; } }
    @media (max-width: 575px) {
      .services-page__card-icon {
        font-size: 40px;
        width: auto; } }

.card-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1.3; }
  @media (max-width: 991px) {
    .card-title {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .card-title {
      font-size: 15px; } }

.card-link {
  font-size: 14px;
  color: #144b93;
  font-weight: 600;
  letter-spacing: 1px; }
  @media (max-width: 991px) {
    .card-link {
      font-size: 13px; } }

.card-text {
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 1.3; }
  @media (max-width: 991px) {
    .card-text {
      font-size: 12px; } }

.section-service-page {
  padding: 98px 0 90px; }
  @media (max-width: 1229px) {
    .section-service-page {
      padding: 70px 0; } }
  @media (max-width: 991px) {
    .section-service-page {
      padding: 55px 0; } }
  @media (max-width: 767px) {
    .section-service-page {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section-service-page {
      padding: 30px 0; } }
  .section-service-page p {
    line-height: 1.45;
    letter-spacing: 1.04px; }

.service-page__wrap {
  display: flex; }
  @media (max-width: 991px) {
    .service-page__wrap {
      flex-direction: column-reverse; } }

.service-page__nav {
  width: 260px;
  flex-shrink: 0;
  background-color: #f1f1f1;
  padding: 29px 28px;
  margin-left: 81px; }
  @media (max-width: 1229px) {
    .service-page__nav {
      width: 255px;
      margin-left: 35px; } }
  @media (max-width: 991px) {
    .service-page__nav {
      margin-left: 0;
      width: 100%;
      padding: 20px;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      left: 0;
      top: 29px;
      max-height: 300px;
      overflow-y: auto;
      transition: all 0.15s ease; } }
  .service-page__nav-decoration-img-1 {
    bottom: -447px;
    right: 16px; }
    @media (max-width: 1229px) {
      .service-page__nav-decoration-img-1 {
        bottom: -300px; } }
    @media (max-width: 991px) {
      .service-page__nav-decoration-img-1 {
        display: none; } }
  .service-page__nav-wrap {
    position: relative;
    align-self: flex-start; }
    @media (max-width: 991px) {
      .service-page__nav-wrap {
        align-self: auto; } }
    @media (max-width: 991px) {
      .service-page__nav-wrap.open .service-page__nav {
        opacity: 1;
        visibility: visible;
        position: absolute; } }
    @media (max-width: 991px) {
      .service-page__nav-wrap.open .service-page__nav-btn-line:before {
        transform: rotateZ(90deg); } }
  .service-page__nav-ttl {
    text-align: center; }
    @media (max-width: 991px) {
      .service-page__nav-ttl {
        display: none; } }
  .service-page__nav-btn {
    position: relative;
    padding-left: 40px; }
    @media (min-width: 992px) {
      .service-page__nav-btn {
        display: none; } }
    .service-page__nav-btn-line {
      width: 34px;
      height: 34px;
      background-color: #f1f1f1;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto; }
      .service-page__nav-btn-line:after, .service-page__nav-btn-line:before {
        content: '';
        position: absolute;
        background-color: #144b93;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: transform 0.15s ease; }
      .service-page__nav-btn-line:after {
        width: 24px;
        height: 4px; }
      .service-page__nav-btn-line:before {
        height: 24px;
        width: 4px; }

@media (max-width: 991px) {
  .service-page__main {
    margin-top: 30px; } }

.service-page__main-img {
  text-align: center; }
  .service-page__main-img:nth-child(n + 2) {
    margin: 53px 0 51px; }
    @media (max-width: 991px) {
      .service-page__main-img:nth-child(n + 2) {
        margin: 30px 0; } }

.service-page__main-images {
  margin: 32px 0 47px;
  display: flex; }
  @media (max-width: 991px) {
    .service-page__main-images {
      margin: 25px 0; } }
  @media (max-width: 575px) {
    .service-page__main-images {
      flex-wrap: wrap; } }
  .service-page__main-images-item {
    margin-right: 15px; }
    @media (max-width: 575px) {
      .service-page__main-images-item {
        margin-bottom: 15px;
        margin-right: 0; } }
    .service-page__main-images-item:last-child {
      margin-right: 0; }
      @media (max-width: 575px) {
        .service-page__main-images-item:last-child {
          margin-bottom: 0; } }

.service-page__main p:nth-child(n + 2) {
  margin-top: 20px; }
  @media (max-width: 991px) {
    .service-page__main p:nth-child(n + 2) {
      margin-top: 15px; } }

.service-page__list {
  margin-top: 27px; }
  @media (max-width: 991px) {
    .service-page__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0; } }
  .service-page__list li {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    line-height: 1.28; }
    @media (max-width: 991px) {
      .service-page__list li {
        width: 30%;
        margin-right: 5%; } }
    @media (max-width: 767px) {
      .service-page__list li {
        width: 100%;
        margin-right: 0; } }
    .service-page__list li.active a {
      color: #144b93; }
    .service-page__list li:nth-child(n + 2) {
      margin-top: 16px; }
      @media (max-width: 991px) {
        .service-page__list li:nth-child(n + 2) {
          margin-top: 0; } }
      @media (max-width: 767px) {
        .service-page__list li:nth-child(n + 2) {
          margin-top: 12px; } }
    @media (max-width: 991px) {
      .service-page__list li:nth-child(3n) {
        margin-right: 0; } }
    @media (max-width: 991px) {
      .service-page__list li:nth-child(n + 4) {
        margin-top: 12px; } }
    .service-page__list li a:hover {
      color: #144b93;
      transition: color 0.15s ese; }

.service-page__form-wrap {
  margin-top: 55px;
  max-width: 640px; }
  @media (max-width: 991px) {
    .service-page__form-wrap {
      margin-top: 30px; } }

.service-page__form-ttl {
  text-align: center; }

.service-page__form-inner {
  margin-top: 41px;
  display: flex; }
  @media (max-width: 991px) {
    .service-page__form-inner {
      margin-top: 25px; } }
  @media (max-width: 767px) {
    .service-page__form-inner {
      flex-wrap: wrap; } }

.service-page__form-inputs {
  width: 320px;
  margin-right: 20px; }
  .service-page__form-inputs .form-group:nth-child(n + 2) {
    margin-top: 13px; }
  @media (max-width: 767px) {
    .service-page__form-inputs {
      width: 100%;
      margin-right: 0; } }

.service-page__form-textarea {
  width: 300px; }
  @media (max-width: 767px) {
    .service-page__form-textarea {
      width: 100%;
      margin-top: 13px; } }

.service-page__form-btn {
  margin-top: 21px;
  text-align: right; }

.section-testimonials {
  padding: 99px 0 67px; }
  @media (max-width: 1229px) {
    .section-testimonials {
      padding: 70px 0 57px; } }
  @media (max-width: 991px) {
    .section-testimonials {
      padding: 55px 0; } }
  @media (max-width: 767px) {
    .section-testimonials {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section-testimonials {
      padding: 30px 0; } }

.testimonials__decoration-img-1 {
  left: -318px;
  top: 36.9%; }
  @media (max-width: 1229px) {
    .testimonials__decoration-img-1 {
      left: -170px; } }
  @media (max-width: 575px) {
    .testimonials__decoration-img-1 {
      display: none; } }

.testimonials__decoration-img-2 {
  right: -217px;
  top: 34.2%; }
  @media (max-width: 1229px) {
    .testimonials__decoration-img-2 {
      right: -160px; } }
  @media (max-width: 575px) {
    .testimonials__decoration-img-2 {
      display: none; } }

.testimonials__wrap {
  width: 940px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  .testimonials__wrap .section-pagination {
    margin-top: 60px; }
    @media (max-width: 1229px) {
      .testimonials__wrap .section-pagination {
        margin-top: 45px; } }
    @media (max-width: 991px) {
      .testimonials__wrap .section-pagination {
        margin-top: 35px; } }
    @media (max-width: 767px) {
      .testimonials__wrap .section-pagination {
        margin-top: 25px; } }
    @media (max-width: 575px) {
      .testimonials__wrap .section-pagination {
        text-align: center; } }

.testimonials__items {
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 575px) {
    .testimonials__items {
      justify-content: center; } }

.testimonials__item {
  width: 300px;
  max-width: 100%;
  background-color: #e5e5e5;
  margin-right: 20px;
  max-width: 300px; }
  @media (max-width: 1229px) {
    .testimonials__item {
      width: 296px; } }
  @media (max-width: 991px) {
    .testimonials__item {
      width: 49%;
      margin-right: 0; } }
  @media (max-width: 575px) {
    .testimonials__item {
      width: 100%; } }
  .testimonials__item:nth-child(3n) {
    margin-right: 0; }
  .testimonials__item:nth-child(n + 4) {
    margin-top: 22px; }
  @media (max-width: 991px) {
    .testimonials__item:nth-child(2n) {
      margin-left: 2%; } }
  @media (max-width: 575px) {
    .testimonials__item:nth-child(2n) {
      margin-left: 0; } }
  @media (max-width: 991px) {
    .testimonials__item:nth-child(n + 3) {
      margin-top: 15px; } }
  @media (max-width: 767px) {
    .testimonials__item:nth-child(n + 3) {
      margin-top: 10px; } }
  @media (max-width: 575px) {
    .testimonials__item:nth-child(n + 2) {
      margin-top: 15px; } }
  .testimonials__item-video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 0;
    z-index: 0;
    padding-bottom: 200px; }
    .testimonials__item-video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .testimonials__item-video-wrap {
      position: relative;
      height: 200px;
      background-color: #bfbfbf; }
      .testimonials__item-video-wrap .testimonials__item-img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 1;
        transform: scale(1);
        transition: all 0.15s ease; }
        .testimonials__item-video-wrap .testimonials__item-img.no-active {
          opacity: 0;
          visibility: hidden;
          transform: scale(0.1); }
        .testimonials__item-video-wrap .testimonials__item-img:hover .testimonials__item-play {
          color: #144b93; }
  .testimonials__item-img {
    position: relative;
    height: 200px;
    background-color: #bfbfbf; }
    .testimonials__item-img-1, .testimonials__item-img-2 {
      height: 100%;
      width: 100%; }
      .testimonials__item-img-1 img,
      .testimonials__item-img-1 div, .testimonials__item-img-2 img,
      .testimonials__item-img-2 div {
        width: 100%;
        height: 100%; }
      .testimonials__item-img-1 span, .testimonials__item-img-2 span {
        position: absolute;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
        bottom: 21px;
        letter-spacing: 1px; }
    .testimonials__item-img-1 {
      z-index: 1; }
      .testimonials__item-img-1 span {
        left: 19px; }
    .testimonials__item-img-2 span {
      right: 19px; }
    .testimonials__item-img.cocoen img {
      height: 100%;
      width: 100%; }
    .testimonials__item-img.cocoen .cocoen-drag {
      width: 3px;
      background: #bfbfbf;
      z-index: 2; }
      .testimonials__item-img.cocoen .cocoen-drag:before {
        background: url(../images/useful/testimonials/cocoen-drag.png) no-repeat;
        border: none;
        width: 33px;
        height: 33px;
        z-index: 2;
        left: 1px;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0; }
  .testimonials__item-play {
    display: flex;
    position: absolute;
    bottom: 11px;
    left: 14px;
    opacity: 0.7;
    color: #1a1a1a;
    font-size: 62px;
    transition: color 0.15s ease; }
  .testimonials__item-info {
    padding: 30px 18px 52px 18px; }
    @media (max-width: 767px) {
      .testimonials__item-info {
        padding: 18px 14px 30px 14px; } }
    .testimonials__item-info-rating {
      display: flex; }
    .testimonials__item-info-ttl {
      margin-top: 12px; }
    .testimonials__item-info-about {
      font-size: 11px;
      color: #686868;
      font-weight: 600;
      margin-top: 7px;
      letter-spacing: 0.8px;
      line-height: 1.65; }
    .testimonials__item-info-descr {
      font-size: 13px;
      margin-top: 16px;
      letter-spacing: 0.99px;
      line-height: 1.231; }
      @media (max-width: 767px) {
        .testimonials__item-info-descr {
          margin-top: 10px; } }
    .testimonials__item-info-link {
      font-weight: bold;
      margin-top: 13px;
      letter-spacing: 1px; }
      .testimonials__item-info-link a {
        color: #144b93;
        transition: color 0.15s ease; }
        .testimonials__item-info-link a:hover {
          color: #3269b1; }

.education__decoration-img-1 {
  right: -5.8%;
  top: -8.5%; }
  @media (max-width: 1229px) {
    .education__decoration-img-1 {
      right: -5%;
      top: -7%; } }
  @media (max-width: 1229px) {
    .education__decoration-img-1 {
      right: 4%;
      top: -5%; } }
  @media (max-width: 767px) {
    .education__decoration-img-1 {
      display: none; } }

.education__decoration-img-2 {
  top: 31.6%;
  left: -30.1%; }
  @media (max-width: 1229px) {
    .education__decoration-img-2 {
      left: -20%; } }
  @media (max-width: 991px) {
    .education__decoration-img-2 {
      left: -25%; } }
  @media (max-width: 767px) {
    .education__decoration-img-2 {
      display: none; } }

.education__item .testimonials__item-info {
  padding: 6px 18px 37px 18px; }
  @media (max-width: 767px) {
    .education__item .testimonials__item-info {
      padding: 6px 14px 30px 14px; } }

.education__item .testimonials__item-info-about {
  margin-top: 2px; }

.rating-star {
  font-size: 16px;
  display: flex; }
  .rating-star:nth-child(n + 2) {
    margin-left: 5px; }
  .rating-star_fill {
    color: #fff474; }
  .rating-star_empty {
    color: #fff; }

.pagination__list li {
  display: inline-block;
  margin-right: 9px; }
  .pagination__list li:last-child {
    margin-right: 0; }
  .pagination__list li.active a,
  .pagination__list li.active span {
    background-color: #727272;
    color: #fff; }
  .pagination__list li a,
  .pagination__list li span {
    display: inline-block;
    border: 2px solid #727272;
    min-width: 30px;
    min-height: 30px;
    color: #727272;
    font-weight: 600; }
  .pagination__list li a {
    padding: 5px 10px; }
  .pagination__list li span {
    padding: 5px 15px; }

.section-offers {
  padding: 241px 0 299px; }
  @media (max-width: 1229px) {
    .section-offers {
      padding: 180px 0; } }
  @media (max-width: 991px) {
    .section-offers {
      padding: 100px 0 60px; } }
  @media (max-width: 767px) {
    .section-offers {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section-offers {
      padding: 30px 0; } }

.offers__wrap {
  position: relative; }

.offers__decoration-img-1 {
  top: -188px;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 1229px) {
    .offers__decoration-img-1 {
      top: -160px; } }
  @media (max-width: 991px) {
    .offers__decoration-img-1 {
      top: -105px; } }
  @media (max-width: 767px) {
    .offers__decoration-img-1 {
      display: none; } }

.offers__decoration-img-2 {
  bottom: -306px;
  left: 52.5%;
  transform: translateX(-50%); }
  @media (max-width: 1229px) {
    .offers__decoration-img-2 {
      bottom: -260px; } }
  @media (max-width: 991px) {
    .offers__decoration-img-2 {
      display: none; } }

.offers__items {
  display: flex;
  align-items: center;
  flex-direction: column; }

.offers__item {
  display: flex;
  background-color: #e6e6e6;
  width: 620px;
  max-width: 100%;
  box-shadow: 0px 17px 38.4px 1.6px rgba(0, 0, 0, 0.31); }
  @media (max-width: 575px) {
    .offers__item {
      flex-direction: column;
      box-shadow: 0px 8px 26.4px 0.6px rgba(0, 0, 0, 0.31); } }
  .offers__item:nth-child(n + 2) {
    margin-top: 69px; }
    @media (max-width: 991px) {
      .offers__item:nth-child(n + 2) {
        margin-top: 55px; } }
    @media (max-width: 767px) {
      .offers__item:nth-child(n + 2) {
        margin-top: 45px; } }
    @media (max-width: 575px) {
      .offers__item:nth-child(n + 2) {
        margin-top: 30px; } }
  .offers__item-img {
    min-height: 200px;
    width: 299px;
    flex-shrink: 0; }
    @media (max-width: 767px) {
      .offers__item-img {
        width: 250px; } }
    @media (max-width: 575px) {
      .offers__item-img {
        width: 100%; } }
  .offers__item-info {
    padding: 26px 21px 31px;
    text-align: center;
    width: 100%; }
    @media (max-width: 767px) {
      .offers__item-info {
        padding: 15px 15px 20px; } }
    .offers__item-info-price {
      color: #1044a2;
      letter-spacing: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; }
      .offers__item-info-price-old {
        color: #666666;
        text-decoration: line-through; }
      .offers__item-info-price span:nth-child(n + 2) {
        margin-left: 12px; }
    .offers__item-info-ttl {
      margin-top: 12px; }
      @media (max-width: 767px) {
        .offers__item-info-ttl {
          margin-top: 8px; } }
      .offers__item-info-ttl .card-title {
        line-height: 1; }
    .offers__item-info-about {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 1.2px;
      margin-top: 7px; }
      @media (max-width: 767px) {
        .offers__item-info-about {
          font-size: 14px;
          margin-top: 5px; } }
    .offers__item-info-descr {
      letter-spacing: 0.5px;
      margin-top: 9px;
      font-size: 13px;
      line-height: 1.4;
      font-weight: 500; }
      @media (max-width: 767px) {
        .offers__item-info-descr {
          margin-top: 7px; } }
    .offers__item-info-link {
      margin-top: 18px; }
      @media (max-width: 767px) {
        .offers__item-info-link {
          margin-top: 12px; } }

.section-about {
  padding: 94px 0; }
  @media (max-width: 1229px) {
    .section-about {
      padding: 80px 0; } }
  @media (max-width: 991px) {
    .section-about {
      padding: 60px 0; } }
  @media (max-width: 767px) {
    .section-about {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section-about {
      padding: 30px 0; } }
  .section-about p {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1.2px; }
    @media (max-width: 991px) {
      .section-about p {
        font-size: 15px; } }
    @media (max-width: 767px) {
      .section-about p {
        font-size: 13px; } }
    .section-about p:nth-child(n + 2) {
      margin-top: 24px; }
      @media (max-width: 991px) {
        .section-about p:nth-child(n + 2) {
          margin-top: 15px; } }
      @media (max-width: 767px) {
        .section-about p:nth-child(n + 2) {
          margin-top: 10px; } }
  .section-about h3,
  .section-about h4 {
    text-align: center; }
  .section-about h4 {
    font-weight: 600; }

.about__video {
  margin-left: auto;
  margin-right: auto; }

.about__decoration-img-1 {
  right: -23.6%;
  top: -3.8%; }
  @media (max-width: 1229px) {
    .about__decoration-img-1 {
      right: -18%; } }
  @media (max-width: 767px) {
    .about__decoration-img-1 {
      display: none; } }

.about__wrap {
  display: flex; }
  @media (max-width: 991px) {
    .about__wrap {
      flex-direction: column; } }

.about__descr {
  padding-left: 40px; }
  @media (max-width: 991px) {
    .about__descr {
      padding-left: 0;
      margin-top: 25px; } }
  @media (max-width: 767px) {
    .about__descr {
      margin-top: 15px; } }
  .about__descr h3 {
    text-align: left; }
    @media (max-width: 991px) {
      .about__descr h3 {
        text-align: center; } }

.about__img {
  width: 300px;
  height: 470px;
  flex-shrink: 0; }
  @media (max-width: 991px) {
    .about__img {
      width: 100%;
      height: 350px; } }

.about__cont {
  margin-top: 60px; }
  @media (max-width: 991px) {
    .about__cont {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    .about__cont {
      margin-top: 20px; } }
  .about__cont h3 {
    margin-bottom: 32px; }
    @media (max-width: 991px) {
      .about__cont h3 {
        margin-bottom: 15px; } }
  .about__cont h4 {
    margin: 28px 0; }
    @media (max-width: 991px) {
      .about__cont h4 {
        margin: 15px 0; } }

.about__quality {
  display: flex;
  margin-top: 27px; }
  @media (max-width: 991px) {
    .about__quality {
      margin-top: 20px;
      flex-direction: column; } }
  .about__quality-img {
    position: relative;
    border: 3px solid #144b93;
    width: 370px;
    height: 370px;
    border-radius: 50%;
    margin: 49px 34px 3px 34px; }
    @media (max-width: 991px) {
      .about__quality-img {
        width: 300px;
        height: 300px; } }
    @media (max-width: 575px) {
      .about__quality-img {
        width: 230px;
        height: 230px;
        margin: 30px 19px 3px 20px; } }
    .about__quality-img-wrap {
      width: 495px;
      flex-shrink: 0;
      padding: 41px 0 41px 60px; }
      @media (max-width: 991px) {
        .about__quality-img-wrap {
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 15px 0; } }

.about__images {
  margin-top: 12px;
  display: flex;
  justify-content: center; }
  @media (max-width: 575px) {
    .about__images {
      flex-direction: column; } }
  .about__images-item {
    position: relative;
    width: 460px;
    height: 300px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px; }
    @media (max-width: 575px) {
      .about__images-item {
        margin-right: 0; } }
    .about__images-item:last-child {
      margin-right: 0; }
    @media (max-width: 575px) {
      .about__images-item:nth-child(n + 2) {
        margin-top: 10px; } }
    .about__images-item:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #000;
      opacity: 0.1;
      z-index: 1; }
    .about__images-item-ttl {
      position: relative;
      color: #fff;
      text-align: center; }
      .about__images-item-ttl span {
        display: block;
        font-weight: 600; }
        .about__images-item-ttl span.font-sansita {
          line-height: 1;
          font-size: 62px;
          letter-spacing: 3px; }

.quality-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #292929;
  overflow: hidden;
  position: absolute;
  line-height: 1;
  letter-spacing: 1.2px;
  text-align: center;
  word-wrap: break-word;
  font-weight: 500; }
  @media (max-width: 991px) {
    .quality-circle {
      width: 80px;
      height: 80px;
      font-size: 13px; } }
  @media (max-width: 575px) {
    .quality-circle {
      width: 70px;
      height: 70px;
      font-size: 10px; } }
  .quality-circle_big {
    height: 122px;
    width: 122px;
    color: #fff; }
    @media (max-width: 991px) {
      .quality-circle_big {
        width: 90px;
        height: 90px; } }
    @media (max-width: 575px) {
      .quality-circle_big {
        width: 80px;
        height: 80px; } }
  .quality-circle_light-blue {
    background-color: #3eb5f1; }
  .quality-circle_light-orange {
    background-color: #e18c44; }
  .quality-circle_orange {
    background-color: #d3751a; }
  .quality-circle_yellow {
    background-color: #d8df23; }
  .quality-circle_green {
    background-color: #92c37c; }
  .quality-circle_blue {
    background-color: #144b93; }
  .quality-circle_top {
    top: -14.3%;
    left: 50%;
    transform: translateX(-50%); }
  .quality-circle_left {
    left: -9.8%;
    top: 19.6%; }
  .quality-circle_left-bottom {
    bottom: -1.3%;
    left: 6%; }
  .quality-circle_right {
    right: -9.8%;
    top: 19.6%; }
  .quality-circle_right-bottom {
    bottom: -1.3%;
    right: 6%; }
  .quality-circle_center {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto; }

.section-contacts {
  display: flex;
  justify-content: flex-end;
  padding: 94px 0 81px; }
  @media (max-width: 1229px) {
    .section-contacts {
      padding: 75px 0; } }
  @media (max-width: 991px) {
    .section-contacts {
      padding: 60px 0; } }
  @media (max-width: 767px) {
    .section-contacts {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section-contacts {
      padding: 30px 0; } }

.contacts__wrap {
  width: 1470px;
  max-width: 100%;
  padding-left: 20px;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1229px) {
    .contacts__wrap {
      justify-content: flex-end; } }
  @media (max-width: 991px) {
    .contacts__wrap {
      flex-direction: column;
      padding-left: 0; } }

.contacts__lt {
  width: 360px;
  margin-right: 20px;
  max-width: 100%; }
  @media (max-width: 991px) {
    .contacts__lt {
      padding: 0 15px;
      margin: 0 auto;
      width: 100%; } }

.contacts__rt {
  width: 1050px;
  max-width: 100%;
  min-height: 300px; }
  @media (max-width: 1229px) {
    .contacts__rt {
      width: 700px; } }
  @media (max-width: 991px) {
    .contacts__rt {
      width: 100%;
      height: 400px;
      margin-top: 25px; } }
  @media (max-width: 767px) {
    .contacts__rt {
      height: 300px; } }

.contacts__address-ttl {
  letter-spacing: 1.8px; }

.contacts__address-info, .contacts__address-call {
  font-size: 16px;
  color: #1f1f1f;
  line-height: 1.5;
  font-weight: 500;
  margin-top: 20px;
  letter-spacing: 1.15px; }
  @media (max-width: 991px) {
    .contacts__address-info, .contacts__address-call {
      margin-top: 12px;
      font-size: 13px; } }

.contacts__address-call span {
  font-weight: 600; }

.contacts__form {
  margin-top: 40px;
  max-width: 320px; }
  @media (max-width: 991px) {
    .contacts__form {
      max-width: none;
      margin-top: 20px; } }
  .contacts__form-inputs {
    margin-top: 24px; }
    @media (max-width: 991px) {
      .contacts__form-inputs {
        margin-top: 15px; } }
    .contacts__form-inputs .form-group:nth-child(n + 2) {
      margin-top: 15px; }

.section-insurance {
  padding: 77px 0 46px; }
  @media (max-width: 1229px) {
    .section-insurance {
      padding: 70px 0 30px; } }
  @media (max-width: 991px) {
    .section-insurance {
      padding: 60px 0 30px; } }
  @media (max-width: 767px) {
    .section-insurance {
      padding: 40px 0 30px; } }
  @media (max-width: 575px) {
    .section-insurance {
      padding: 30px 0; } }

.insurance__text {
  max-width: 563px;
  margin: 0 auto;
  position: relative; }
  .insurance__text p {
    line-height: 1.286;
    letter-spacing: 1.05px; }
    .insurance__text p:nth-child(n + 2) {
      margin-top: 18px; }
      @media (max-width: 991px) {
        .insurance__text p:nth-child(n + 2) {
          margin-top: 12px; } }
  .insurance__text-decoration-img-1 {
    left: -32%;
    top: 22%; }
    @media (max-width: 1229px) {
      .insurance__text-decoration-img-1 {
        left: -19%; } }
    @media (max-width: 767px) {
      .insurance__text-decoration-img-1 {
        display: none; } }

.insurance__companies {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .insurance__companies-wrap {
    position: relative;
    margin-top: 84px;
    padding-bottom: 220px; }
    @media (max-width: 1229px) {
      .insurance__companies-wrap {
        padding-bottom: 170px;
        margin-top: 60px; } }
    @media (max-width: 991px) {
      .insurance__companies-wrap {
        padding-bottom: 135px;
        margin-top: 50px; } }
    @media (max-width: 767px) {
      .insurance__companies-wrap {
        padding-bottom: 0; } }
  .insurance__companies-decoration-img-1 {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 991px) {
      .insurance__companies-decoration-img-1 {
        transform: translateX(-50%) scale(0.6); } }
    @media (max-width: 767px) {
      .insurance__companies-decoration-img-1 {
        display: none; } }

.insurance__company {
  width: 220px;
  height: 110px;
  max-width: 100%;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: border-color 0.4s ease; }
  .insurance__company:hover {
    border-color: #144b93; }
  @media (max-width: 991px) {
    .insurance__company {
      width: 216px; } }
  @media (max-width: 767px) {
    .insurance__company {
      width: 220px; } }
  @media (max-width: 575px) {
    .insurance__company {
      width: 46%; } }
  @media (max-width: 450px) {
    .insurance__company {
      width: 220px;
      margin-right: 0; } }
  .insurance__company:nth-child(4n), .insurance__company:last-child {
    margin-right: 0; }
  @media (max-width: 1229px) {
    .insurance__company:nth-child(4n) {
      margin-right: 20px; } }
  @media (max-width: 1229px) {
    .insurance__company:nth-child(3n) {
      margin-right: 0; } }
  @media (max-width: 767px) {
    .insurance__company:nth-child(3n) {
      margin-right: 20px; } }
  @media (max-width: 450px) {
    .insurance__company:nth-child(3n) {
      margin-right: 0; } }
  @media (max-width: 767px) {
    .insurance__company:nth-child(2n) {
      margin-right: 0; } }
  .insurance__company-img {
    max-width: 100%;
    max-height: 100%; }

.section-gallery {
  padding: 80px 0 99px; }
  @media (max-width: 1229px) {
    .section-gallery {
      padding: 75px 0; } }
  @media (max-width: 991px) {
    .section-gallery {
      padding: 60px 0; } }
  @media (max-width: 767px) {
    .section-gallery {
      padding: 40px 0; } }
  @media (max-width: 575px) {
    .section-gallery {
      padding: 30px 0; } }

.gallery__decoration-office-img-1 {
  left: -24%;
  top: 1.9%; }
  @media (max-width: 1229px) {
    .gallery__decoration-office-img-1 {
      left: -20%; } }
  @media (max-width: 991px) {
    .gallery__decoration-office-img-1 {
      display: none; } }

.gallery__decoration-office-img-2 {
  top: 51.6%;
  right: -25%; }
  @media (max-width: 1229px) {
    .gallery__decoration-office-img-2 {
      right: -20%; } }
  @media (max-width: 991px) {
    .gallery__decoration-office-img-2 {
      display: none; } }

.gallery__decoration-team-img-1 {
  left: -16%;
  top: -0.7%; }
  @media (max-width: 991px) {
    .gallery__decoration-team-img-1 {
      display: none; } }

.gallery__decoration-team-img-2 {
  right: -16.3%;
  top: 40.4%; }
  @media (max-width: 991px) {
    .gallery__decoration-team-img-2 {
      display: none; } }

.gallery__decoration-team-img-3 {
  bottom: -8.7%;
  left: -13%; }
  @media (max-width: 991px) {
    .gallery__decoration-team-img-3 {
      display: none; } }

.gallery__item {
  display: flex;
  flex-direction: column; }
  .gallery__item:nth-child(n + 2) {
    margin-top: 19px; }
    @media (max-width: 991px) {
      .gallery__item:nth-child(n + 2) {
        margin-top: 15px; } }
  .gallery__item-line {
    display: flex; }
    @media (max-width: 575px) {
      .gallery__item-line {
        flex-direction: column; } }
    .gallery__item-line > *:nth-child(n + 2) {
      margin-left: 20px; }
      @media (max-width: 991px) {
        .gallery__item-line > *:nth-child(n + 2) {
          margin-left: 15px; } }
      @media (max-width: 575px) {
        .gallery__item-line > *:nth-child(n + 2) {
          margin-left: 0;
          margin-top: 15px; } }
    .gallery__item-line:nth-child(n + 2) {
      margin-top: 19px; }
      @media (max-width: 991px) {
        .gallery__item-line:nth-child(n + 2) {
          margin-top: 15px; } }
    .gallery__item-line-video {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      height: 0;
      z-index: 0;
      height: 100%; }
      .gallery__item-line-video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
      .gallery__item-line-video-wrap {
        position: relative;
        background-color: #bfbfbf; }
        .gallery__item-line-video-wrap .gallery__item-line-img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
          cursor: pointer;
          transform: scale(1);
          transition: all 0.15s ease; }
          .gallery__item-line-video-wrap .gallery__item-line-img.no-active {
            opacity: 0;
            visibility: hidden;
            transform: scale(0.1); }
        .gallery__item-line-video-wrap:hover .gallery__item-line-video-play {
          color: #144b93; }
      .gallery__item-line-video-play {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 150px;
        height: 150px;
        width: 150px; }
        @media (max-width: 991px) {
          .gallery__item-line-video-play {
            font-size: 80px;
            width: 80px;
            height: 80px; } }
    .gallery__item-line-img {
      height: 100%; }
      .gallery__item-line-img img {
        transition: transform 1s ease-in-out; }
      .gallery__item-line-img:hover img {
        transform: scale(1.1); }

.item-h-483 {
  height: 483px; }
  @media (max-width: 991px) {
    .item-h-483 {
      height: 425px; } }
  @media (max-width: 767px) {
    .item-h-483 {
      height: 300px; } }

.item-h-470 {
  height: 470px; }
  @media (max-width: 991px) {
    .item-h-470 {
      height: 420px; } }
  @media (max-width: 767px) {
    .item-h-470 {
      height: 300px; } }

.item-h-300 {
  height: 300px; }
  @media (max-width: 991px) {
    .item-h-300 {
      height: 260px; } }
  @media (max-width: 767px) {
    .item-h-300 {
      height: 180px; } }

@media (max-width: 575px) {
  .item-h-300,
  .item-h-470 {
    height: 250px; } }

.item-w-620 {
  width: 620px; }

.item-w-460 {
  width: 460px; }

.item-w-300 {
  width: 300px; }

.item-w-620,
.item-w-460,
.item-w-300 {
  max-width: 100%; }
  @media (max-width: 575px) {
    .item-w-620,
    .item-w-460,
    .item-w-300 {
      width: 100%; } }

.animateIn {
  animation-name: animateIn;
  animation-duration: 1.5s;
  animation-timing-function: ease-in; }

@keyframes animateIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
