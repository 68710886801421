.section-about {
	padding: 94px 0;
	@media(max-width: $sw-md-max) {
		padding: 80px 0;
	}
	@media(max-width: $sw-sm-max) {
		padding: 60px 0;
	}
	@media(max-width: $sw-xs-max) {
		padding: 40px 0;
	}
	@media(max-width: $sw-xx-max) {
		padding: 30px 0;
	}

	p {
		font-size: 16px;
		color: rgb(31, 31, 31);
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: 1.2px;
		@media(max-width: $sw-sm-max) {
			font-size: 15px;
		}
		@media(max-width: $sw-xs-max) {
			font-size: 13px;
		}

		&:nth-child(n + 2) {
			margin-top: 24px;
			@media(max-width: $sw-sm-max) {
				margin-top: 15px;
			}
			@media(max-width: $sw-xs-max) {
				margin-top: 10px;
			}
		}
	}

	h3,
	h4 {
		text-align: center;
	}

	h4 {
		font-weight: 600;
	}
}

.about {
	&__video {
		margin-left: auto;
		margin-right: auto;
	}
	&__decoration {
		&-img-1 {
			right: -23.6%;
			top: -3.8%;
			@media(max-width: $sw-md-max) {
				right: -18%;
			}
			@media(max-width: $sw-xs-max) {
				display: none;
			}
		}
	}
	&__wrap {
		display: flex;
		@media(max-width: $sw-sm-max) {
			flex-direction: column;
		}
	}
	&__descr {
		padding-left: 40px;
		@media(max-width: $sw-sm-max) {
			padding-left: 0;
			margin-top: 25px;
		}
		@media(max-width: $sw-xs-max) {
			margin-top: 15px;
		}

		h3 {
			text-align: left;
			@media(max-width: $sw-sm-max) {
				text-align: center;
			}
		}
	}
	&__img {
		width: 300px;
		height: 470px;
		flex-shrink: 0;
		@media(max-width: $sw-sm-max) {
			width: 100%;
			height: 350px;
		}
	}
	&__cont {
		margin-top: 60px;
		@media(max-width: $sw-sm-max) {
			margin-top: 30px;
		}
		@media(max-width: $sw-xs-max) {
			margin-top: 20px;
		}

		h3 {
			margin-bottom: 32px;
			@media(max-width: $sw-sm-max) {
				margin-bottom: 15px;
			}
		}

		h4 {
			margin: 28px 0;
			@media(max-width: $sw-sm-max) {
				margin: 15px 0;
			}
		}
	}

	&__quality {
		display: flex;
		margin-top: 27px;
		@media(max-width: $sw-sm-max) {
			margin-top: 20px;
			flex-direction: column;
		}

		&-img {
			position: relative;
			border: 3px solid rgb(20, 75, 147);
			width: 370px;
			height: 370px;
			border-radius: 50%;
			margin: 49px 34px 3px 34px;
			@media(max-width: $sw-sm-max) {
				width: 300px;
				height: 300px;
			}
			@media(max-width: $sw-xx-max) {
				width: 230px;
				height: 230px;
				margin: 30px 19px 3px 20px;
			}
			&-wrap {
				width: 495px;
				flex-shrink: 0;
				padding: 41px 0 41px 60px;
				@media(max-width: $sw-sm-max) {
					width: 100%;
					display: flex;
					justify-content: center;
					padding: 15px 0;
				}
			}
		}
	}

	&__images {
		margin-top: 12px;
		display: flex;
		justify-content: center;
		@media(max-width: $sw-xx-max) {
			flex-direction: column;
		}

		&-item {
			position: relative;
			width: 460px;
			height: 300px;
			max-width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 20px;
			@media(max-width: $sw-xx-max) {
				margin-right: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&:nth-child(n + 2) {
				@media(max-width: $sw-xx-max) {
					margin-top: 10px;
				}
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				background-color: #000;
				opacity: 0.1;
				z-index: 1;
			}

			&-ttl {
				position: relative;
				color: #fff;
				text-align: center;
				span {
					display: block;
					font-weight: 600;

					&.font-sansita {
						line-height: 1;
						font-size: 62px;
						letter-spacing: 3px;
					}
				}
			}
		}
	}
}

.quality-circle {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: rgb(41, 41, 41);
	overflow: hidden;
	position: absolute;
	line-height: 1;
	letter-spacing: 1.2px;
	text-align: center;
	word-wrap: break-word;
	font-weight: 500;
	@media(max-width: $sw-sm-max) {
		width: 80px;
		height: 80px;
		font-size: 13px;
	}
	@media(max-width: $sw-xx-max) {
		width: 70px;
		height: 70px;
		font-size: 10px;
	}

	&_big {
		height: 122px;
		width: 122px;
		color: #fff;

		@media(max-width: $sw-sm-max) {
			width: 90px;
			height: 90px;
		}
		@media(max-width: $sw-xx-max) {
			width: 80px;
			height: 80px;
		}
	}


	&_light-blue {
		background-color: #3eb5f1;
	}
	&_light-orange {
		background-color: #e18c44;
	}
	&_orange {
		background-color: #d3751a;
	}
	&_yellow {
		background-color: #d8df23;
	}
	&_green {
		background-color: #92c37c;
	}
	&_blue {
		background-color: #144b93;
	}


	&_top {
		top: -14.3%;
		left: 50%;
		transform: translateX(-50%);
	}
	&_left {
		left: -9.8%;
		top: 19.6%;
	}
	&_left-bottom {
		bottom: -1.3%;
		left: 6%;
	}
	&_right {
		right: -9.8%;
		top: 19.6%;
	}
	&_right-bottom {
		bottom: -1.3%;
		right: 6%;
	}
	&_center {
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
	}
}